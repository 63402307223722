import { Edit, Key } from "@mui/icons-material";
import { Box, Grid, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL


const NavigationArray = [
    { path: "", label: "角色清單", Icon: <Key /> },
    { path: "edit", label: "異動紀錄", Icon: <Edit /> },
];

function RoleLayout() {
    const location = useLocation(); // to get url path
    const navigate = useNavigate();
    const [activeItem, setActiveItem] = useState("");
    const [companyName, setCompanyName] = useState("");
    const { id } = useParams();

    const ItemOnClick = (itemName: string) => {
        setActiveItem(itemName);
        navigate(`${itemName}`);  // relative path
    }

    /** initialize */
    const init = async () => {

    }

    useEffect(() => {
        init();
        setActiveItem(location.pathname.split('/')[2] || "");
    });

    return (
        // Container (base): wrap Nav bar, Main Body
        <Grid sx={{ width: '100%', height: "100vh", padding: "30px", overflow: "auto" }}>

            {/* Title */}
            <Grid xs={12} sx={{ width: '100%', display: "flex", justifyContent: "flex-start", padding: "10px 0px" }}>
                <Typography
                    sx={{ flex: '1 1 auto', textAlign: "left", fontWeight: "bold", fontSize: "30px" }}
                    id="tableTitle"
                    component="div"
                >
                    角色與權限管理
                </Typography>
            </Grid>

            <Grid container>
                {/* navigation bar */}
                {
                    NavigationArray.map((item) => (
                        <ListItem key={item.path} disablePadding dense sx={{ width: "140px", margin: "5px" }}>
                            <ListItemButton selected={activeItem == item.path} onClick={() => ItemOnClick(item.path)} disableRipple sx={{ borderRadius: "10px" }}>
                                <ListItemIcon sx={{ minWidth: "0px" }}>
                                    {item.Icon}
                                </ListItemIcon>
                                <ListItemText primary={item.label} sx={{ padding: "0px 0px 0px 10px" }} />
                            </ListItemButton>
                        </ListItem>
                    ))
                }
            </Grid>

            {/* render Main Body */}
            <Outlet />
        </Grid>
    );
}


export default RoleLayout;