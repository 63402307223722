/*
    custom http request function
*/


const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const REFRESHTOKEN_URL = `${BACKEND_URL}/api/v1/auth/refreshtoken`;

/**
 * GET request
 * 
 * Process access and refresh token.
 */
export async function customGET(url: string) {

    const res = await fetch(url, { credentials: "include" });

    if (res.status == 200) {
        return res;
    }
    // not logged in or access token expired
    else if (res.status == 401) {
        // refresh token
        const res = await fetch(REFRESHTOKEN_URL, { credentials: "include" });

        if (res.status == 200) {
            // fetch again
            const res = await fetch(url, { credentials: "include" });
            return res;
        } else {
            // refresh token expired, require login
            window.location.href = "/login";
            return res;
        }
    }
    // Forbidden
    else if (res.status == 403) {
        alert("🔴 權限不足");
        throw new Error;
    } else {
        return res;
    }
}

/**
 * POST request
 * 
 * Process access and refresh token.
 */
export async function customPOST(url: string, body: object) {

    const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
        credentials: "include",
    });

    if (res.status == 200) {
        return res;
    }
    // not logged in or access token expired
    else if (res.status == 401) {
        // refresh token
        const res = await fetch(REFRESHTOKEN_URL, { credentials: "include" });

        if (res.status == 200) {
            // fetch again
            const res = await fetch(url, {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
                credentials: "include",
            });
            return res;
        } else {
            // refresh token expired, require login
            window.location.href = "/login";
            return res;
        }
    }
    // Forbidden
    else if (res.status == 403) {
        alert("🔴 權限不足");
        throw new Error;
    } else {
        return res;
    }
}

/**
 * POST form request
 * 
 * Process access and refresh token.
 * 
 * see https://stackoverflow.com/questions/35192841/how-do-i-post-with-multipart-form-data-using-fetch
 */
export async function customPOSTForm(url: string, formData: FormData) {

    const res = await fetch(url, {
        method: "POST",
        body: formData,
        credentials: "include",
    });

    if (res.status == 200) {
        return res;
    }
    // not logged in or access token expired
    else if (res.status == 401) {
        // refresh token
        const res = await fetch(REFRESHTOKEN_URL, { credentials: "include" });

        if (res.status == 200) {
            // fetch again
            const res = await fetch(url, {
                method: "POST",
                body: formData,
                credentials: "include",
            });
            return res;
        } else {
            // refresh token expired, require login
            window.location.href = "/login";
            return res;
        }
    }
    // Forbidden
    else if (res.status == 403) {
        alert("🔴 權限不足");
        throw new Error;
    } else {
        return res;
    }
}

/**
 * PUT request
 * 
 * Process access and refresh token.
 */
export async function customPUT(url: string, body: object) {

    const res = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(body),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
        credentials: "include",
    });

    if (res.status == 200) {
        return res;
    }
    // not logged in or access token expired
    else if (res.status == 401) {
        // refresh token
        const res = await fetch(REFRESHTOKEN_URL, { credentials: "include" });

        if (res.status == 200) {
            // fetch again
            const res = await fetch(url, {
                method: "PUT",
                body: JSON.stringify(body),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
                credentials: "include",
            });
            return res;
        } else {
            // refresh token expired, require login
            window.location.href = "/login";
            return res;
        }
    }
    // Forbidden
    else if (res.status == 403) {
        alert("🔴 權限不足");
        throw new Error;
    } else {
        return res;
    }
}

/**
 * DELETE request
 * 
 * Process access and refresh token.
 */
export async function customDELETE(url: string) {

    const res = await fetch(url, {
        method: "DELETE",
        credentials: "include",
    });

    if (res.status == 200) {
        return res;
    }
    // not logged in or access token expired
    else if (res.status == 401) {
        // refresh token
        const res = await fetch(REFRESHTOKEN_URL, { credentials: "include" });

        if (res.status == 200) {
            // fetch again
            const res = await fetch(url, {
                method: "DELETE",
                credentials: "include",
            });
            return res;
        } else {
            // refresh token expired, require login
            window.location.href = "/login";
            return res;
        }
    }
    // Forbidden
    else if (res.status == 403) {
        alert("🔴 權限不足");
        throw new Error;
    } else {
        return res;
    }
}