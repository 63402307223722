import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

// mui
import { Box, Button, Collapse, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';

// mui icons
import { Equalizer, CorporateFare, ReceiptLong, SettingsOutlined, People, Key, ExpandLess, ExpandMore } from '@mui/icons-material';

// misc
import { permissions } from '../localStorage/permission';


const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;


function SideBar({ userData }) {
    const location = useLocation(); // to get url path
    const [activeItem, setActiveItem] = useState("");
    const [open, setOpen] = useState(false);    // settings list
    const navigate = useNavigate();

    const ItemOnClick = (itemName: string) => {
        setActiveItem(itemName);
        navigate(`/${itemName}`);  // Note: does not cause page reload
    }

    const logout = async () => {
        await fetch(`${BACKEND_URL}/api/v1/auth/logout`, { credentials: "include" });
        navigate(0);    // refresh -> login page
        return;
    }

    useEffect(() => {
        const itemName = location.pathname.split('/')[1];
        setActiveItem(itemName);
        setOpen(itemName.startsWith("settings"));
    }, [location.pathname]);

    return (
        <Drawer variant="permanent" sx={{
            width: "220px",
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: "220px",
                boxSizing: 'border-box',
            },
        }}>

            <Stack direction="column" sx={{ display: "flex", padding: "20px 20px" }}>
                <img src="/epicloud.png" alt="epicloud icon" style={{ width: "60%" }}></img>
                <Typography sx={{ width: "100%", fontSize: "24px", fontWeight: "bold", textAlign: "left" }}>Google Cloud 帳務系統</Typography>
            </Stack>

            <Divider />

            <List sx={{ height: "50%" }} dense>
                {
                    permissions.includes("TBD???") &&
                    <ListItem key={"stat"} disablePadding >
                        <ListItemButton selected={activeItem == "stats"} onClick={() => ItemOnClick("stats")}>
                            <ListItemIcon sx={{ minWidth: "0px" }}>
                                <Equalizer />
                            </ListItemIcon>
                            <ListItemText primary={"雲端服務"} sx={{ padding: "0px 0px 0px 10px" }} />
                        </ListItemButton>
                    </ListItem>
                }

                {
                    permissions.includes("CUS001") &&
                    <ListItem key={"company"} disablePadding>
                        <ListItemButton selected={activeItem == "company"} onClick={() => ItemOnClick("company")}>
                            <ListItemIcon sx={{ minWidth: "0px" }}>
                                <CorporateFare />
                            </ListItemIcon>
                            <ListItemText primary={"客戶資料"} sx={{ padding: "0px 0px 0px 10px" }} />
                        </ListItemButton>
                    </ListItem>
                }

                {
                    permissions.includes("CUR001") &&
                    <ListItem key={"settings"} disablePadding>
                        <ListItemButton onClick={() => setOpen(!open)}>
                            <ListItemIcon sx={{ minWidth: "0px" }}>
                                <SettingsOutlined />
                            </ListItemIcon>
                            <ListItemText primary={"設定"} sx={{ padding: "0px 0px 0px 10px" }} />
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                    </ListItem>
                }

                <Collapse in={open} timeout="auto" unmountOnExit>
                    {
                        permissions.includes("CUR001") &&
                        <ListItem key={"settings"} disablePadding>
                            <ListItemButton selected={activeItem == "settings-role"} onClick={() => ItemOnClick("settings-role")}>
                                <ListItemIcon sx={{ minWidth: "0px", margin: "0px 0px 0px 20px" }}>
                                    <Key />
                                </ListItemIcon>
                                <ListItemText primary={"角色與權限管理"} sx={{ padding: "0px 0px 0px 10px" }} />
                            </ListItemButton>
                        </ListItem>
                    }
                </Collapse>

            </List>

            <Box sx={{ padding: "10px" }}>
                <Typography
                    sx={{ flex: '1 1 auto', textAlign: "center", fontWeight: "bold" }}
                    id="username"
                    component="div"
                >
                    {userData.username}
                </Typography>

                <Typography
                    sx={{ flex: '1 1 auto', textAlign: "center", fontSize: "12px" }}
                    id="role"
                    component="div"
                >
                    {userData.email}
                </Typography>

                <Typography
                    sx={{ flex: '1 1 auto', textAlign: "center", fontSize: "12px" }}
                    id="role"
                    component="div"
                >
                    {userData.role}
                </Typography>

                <Box sx={{ alignSelf: "center", padding: "20px 10px" }}>
                    <Button size="small" variant="contained" sx={{ width: "70%" }} onClick={logout}>登出</Button>
                </Box>

                {/* version */}
                {/* <Typography
                    sx={{ flex: '1 1 auto', textAlign: "center", color: "#808080" }}
                    id="version"
                    component="div"
                >
                    v1.0.0
                </Typography> */}
            </Box>

        </Drawer>
    );
}

// material ui note:
// <Stack>: control horizontal or vertical layout
// <Box>: base block for individual sections

export default SideBar;
