import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// mui
import { Button, Dialog, IconButton, Stack, Toolbar, Tooltip, Typography } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TablePagination } from '@mui/material';

// mui icons
import { DeleteOutline } from "@mui/icons-material";

// components
import BindBillingAccountPopup from "./BindBillingAccountPopup";

// types
import { BillingAccount } from "../../../types/Contract.types";


// columns of table header
const headCells = [
    {
        id: "billing_name",
        label: "專案名稱",
        width: "20%",
    },
    {
        id: "id",
        label: "專案ID",
        width: "20%"
    },
    {
        id: "status",
        label: "狀態",
        width: "10%",
    },
    {
        id: "action",
        label: "動作",
        width: "10%",
    },
];


interface props {
    billingAccountList: BillingAccount[];
    setBillingAccountList: React.Dispatch<React.SetStateAction<BillingAccount[]>>;
    linkedAccountList?: BillingAccount[];
    /**
     * disable popup button
     */
    disabled?: boolean;
}


function BindBillingAccountTable({ billingAccountList, setBillingAccountList, linkedAccountList = [], disabled = false }: props) {
    const navigate = useNavigate();
    const { id } = useParams();

    // popup control
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);

    const popupSelectAccount = () => {
        setOpen(true);
    }

    const renderDeleteButton = (billing_id: string, removeAccount) => {
        return (
            <Tooltip title={"remove"} placement="right">
                <IconButton size="small" color="error" sx={{ padding: "0px", margin: "0px" }}
                    onClick={removeAccount(billing_id)}
                    disabled={disabled}
                >
                    {<DeleteOutline fontSize="small" />}
                </IconButton>
            </Tooltip>
        );
    }

    /**
     * remove linked account
     */
    const removeAccount = (billing_id: string) => (event) => {

        // remove the item from rows
        setBillingAccountList(billingAccountList.filter(x => x.id != billing_id));
    }

    useEffect(() => {
    }, []);

    return (
        <div>
            <br></br>
            <Typography
                sx={{ fontWeight: "bold", fontSize: "12px", padding: "0px 30px 0px 0px" }}
                display={"inline"}
                id="tableTitle"
                component="div"
            >
                綁定的帳號
            </Typography>

            {/* render button when not disabled */}
            {
                !disabled && <Button variant="contained" onClick={popupSelectAccount} size="small" sx={{ fontSize: "12px" }}> 選擇帳號 </Button>
            }

            {/* popup */}
            <Dialog open={open} onClose={closeModal} fullWidth maxWidth="md">
                <BindBillingAccountPopup closeCurrentPopup={closeModal} billingAccountList={billingAccountList} setBillingAccountList={setBillingAccountList} linkedAccountList={linkedAccountList} />
            </Dialog>

            <TableContainer sx={{ height: "90%" }}>
                <Table
                    stickyHeader
                    aria-labelledby="tableTitle"
                    size="small" // control row density
                >
                    {/* table header */}
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align={"left"}
                                    sx={{ fontSize: "12px" }}
                                    width={headCell.width}
                                >
                                    {headCell.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    {/* table body */}
                    <TableBody>
                        {billingAccountList.map((row, index) => {

                            return (
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={row.id}
                                >
                                    <TableCell
                                        component="th"
                                        id={`table-row-${index}`}
                                        scope="row"
                                        align="left"
                                        width="20%"
                                        sx={{ fontSize: "12px" }}
                                    >
                                        {row.billing_name}
                                    </TableCell>

                                    <TableCell
                                        align="left"
                                        width="20%"
                                        sx={{ fontSize: "12px" }}
                                    >
                                        {row.id}
                                    </TableCell>

                                    <TableCell
                                        align="left"
                                        width="10%"
                                        sx={{ fontSize: "12px" }}
                                    >
                                        {row.status ? "啟用" : "停用"}
                                    </TableCell>

                                    <TableCell
                                        align="left"
                                        width="10%"
                                        sx={{ fontSize: "12px" }}
                                    >
                                        {renderDeleteButton(row.id, removeAccount)}
                                    </TableCell>
                                </TableRow>
                            );
                        })}

                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default BindBillingAccountTable;
