

import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// mui
import { Button, Checkbox, Dialog, TableFooter, Toolbar, Tooltip, Typography } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TablePagination } from '@mui/material';


// types
import { BillingAccount } from "../../../types/Contract.types";

// utils
import { customGET } from "../../../utils/customFetch";

// misc
import { SERVER_ERROR } from "../../../shared/errorMessage";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;


// columns of table header
const headCells = [
    {
        id: "checkbox",
        label: "選擇",
        width: "10%",
    },
    {
        id: "billing_name",
        label: "專案名稱",
        width: "20%",
    },
    {
        id: "id",
        label: "專案ID",
        width: "20%",
    },
    {
        id: "status",
        label: "狀態",
        width: "10%",
    },
];

interface props {
    closeCurrentPopup: any;
    billingAccountList: BillingAccount[];
    setBillingAccountList: React.Dispatch<React.SetStateAction<BillingAccount[]>>;
    linkedAccountList?: BillingAccount[];
}


function BindBillingAccountPopup({ closeCurrentPopup, billingAccountList, setBillingAccountList, linkedAccountList = [] }: props) {
    const location = useLocation(); // to get url path
    const navigate = useNavigate();
    const [accountObjectList, setAccountObjectList] = useState<Array<BillingAccount>>([]); // existing billing account list
    const [selectedAccountList, setSelectedAccountList] = useState<Array<string>>([]); // selected account list (id)
    const { id } = useParams();
    const target = location.pathname.split('/')[3]; // "contract" or "account"


    // popup control
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);


    const popupSelectAccount = () => {
        setOpen(true);
    }

    const cancelAction = () => {
        closeCurrentPopup();
    }

    // set rows (passed from parent)
    const saveAction = async () => {

        setBillingAccountList(accountObjectList.filter(item => selectedAccountList.indexOf(item.id) != -1));

        closeCurrentPopup();
    }

    /**
     * fetch billing account list
     */
    const fetchBillingAccount = async () => {
        const params = new URLSearchParams({
            customer_id: id,
            target: target,
        });

        try {
            const res = await customGET(`${BACKEND_URL}/api/v1/billing-account/customer/unlinked?${params}`);
            var list: Array<any> = await res.json();

            if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }
        } catch (error) {
            console.log(error);
            return;
        }


        setAccountObjectList(list.concat(linkedAccountList));
    }

    /**
     * update selected list
     */
    const onCheck = (billing_id: string) => (event) => {
        const idx = selectedAccountList.indexOf(billing_id);

        if (idx == -1)
            setSelectedAccountList(selectedAccountList.concat(billing_id));
        else
            setSelectedAccountList(selectedAccountList.filter(x => x != billing_id));
    }

    const init = async () => {
        await fetchBillingAccount();

        // set selected list
        setSelectedAccountList(billingAccountList.map((row) => row.id));
    }

    // when popup closed, update account list
    useEffect(() => {
        if (!open)
            init();
    }, [open]);

    return (
        <div>
            <Toolbar>
                <Typography
                    sx={{ textAlign: "left", fontWeight: "bold", fontSize: "12px", flex: "1 1 auto" }}
                    id="tableTitle"
                    component="div"
                >
                    選擇欲加入的帳號：
                </Typography>

            </Toolbar>

            <TableContainer sx={{ height: "90%" }}>
                <Table
                    stickyHeader
                    aria-labelledby="tableTitle"
                    size="small" // control row density
                >
                    {/* table header */}
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align={"left"}
                                    sx={{ fontSize: "12px" }}
                                    width={headCell.width}
                                >
                                    {headCell.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    {/* table body */}
                    <TableBody>
                        {accountObjectList.map((row, index) => {

                            return (
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={row.id}
                                    selected={selectedAccountList.indexOf(row.id) != -1}
                                >
                                    {/* checkbox */}
                                    <TableCell padding="checkbox" width="10%">
                                        <Checkbox
                                            color="primary"
                                            checked={selectedAccountList.indexOf(row.id) != -1}
                                            onChange={onCheck(row.id)}
                                            size="small"
                                        />
                                    </TableCell>

                                    <TableCell
                                        component="th"
                                        id={`table-row-${index}`}
                                        scope="row"
                                        align="left"
                                        width="20%"
                                        sx={{ fontSize: "12px" }}
                                    >
                                        {row.billing_name}
                                    </TableCell>

                                    <TableCell
                                        align="left"
                                        width="20%"
                                        sx={{ fontSize: "12px" }}
                                    >
                                        {row.id}
                                    </TableCell>

                                    <TableCell
                                        align="left"
                                        width="10%"
                                        sx={{ fontSize: "12px" }}
                                    >
                                        {row.status ? "啟用" : "停用"}
                                    </TableCell>

                                </TableRow>
                            );
                        })}

                    </TableBody>
                </Table>
                <TableFooter sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <TableRow sx={{ padding: "10px 0px" }}>
                        <Button variant="contained" color="inherit" size="small" sx={{ margin: "auto 10px" }} onClick={cancelAction}> 取消 </Button>
                        <Button variant="contained" color="primary" size="small" sx={{ margin: "auto 10px" }} onClick={saveAction}> 儲存 </Button>
                    </TableRow>
                </TableFooter>
            </TableContainer>
        </div>
    );
}

export default BindBillingAccountPopup;
