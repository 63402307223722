import { useNavigate } from "react-router-dom";

// mui
import { Add } from "@mui/icons-material";
import { Button, Toolbar, Tooltip, Typography } from "@mui/material";


// (above Table header): table title, export button, create button
function TableToolbar() {
    const navigate = useNavigate();

    const redirectToContractForm = () => {
        navigate('newform'); // redirect to form page
    }

    return (
        <Toolbar sx={{ padding: "20px 0px 0px", borderRadius: "20px 20px 0px 0px", background: "white" }} variant="dense" >
            <Typography
                sx={{ flex: '1 1 auto', textAlign: "left", fontWeight: "bold", fontSize: "20px" }}
                id="tableTitle"
                component="div"
            >
                合約列表
            </Typography>
        </Toolbar>
    );
}


export default TableToolbar;