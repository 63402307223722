/**
 * adapted from Companyinfo.tsx
 */
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

// mui
import { Button, Dialog, DialogActions, DialogTitle, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';

// types
import { Account } from '../../types/Account.types';
import { Role } from '../../types/Role.types';
import { BillingAccount } from '../../types/Contract.types';

// css
import { SelectDisplayProps, inputLabelStyle, inputPropsStyle, inputSX } from '../../css/formStyle';

// components
import BindBillingAccountTable from '../BillingAccount/BindBillingAccount/BindBillingAccountTable';

// utils
import { customDELETE, customGET, customPUT } from '../../utils/customFetch';

// misc
import { enabled } from '../../shared/formSelectOptions';
import { permissions } from '../../localStorage/permission';
import { NOT_FOUND, SERVER_ERROR } from '../../shared/errorMessage';


const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;


// helper text
const RequiredLabel = () => (<span><span style={{ color: "red" }}>*</span> <span style={{ color: "grey" }}>(必填)</span></span>);


const initialFormData: Account = {
    id: "",
    role_id: "",
    role_name: "",
    customer_id: "",
    name: "",
    google_login_mail: "",
    status: "啟用",
    is_default: true,
    create_datetime: null,
    update_datetime: null,
}

// columns that require validation
const initErrColumn = {
    role_name: false,
    name: false,
    google_login_mail: false,
}

/**
 * The default post-action navigation will be overridden by closeCurrentPopup if closeCurrentPopup is not null
 */
function AccountForm() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState<Account>(initialFormData); // render current form state
    const [errColumn, setErrColumn] = useState(initErrColumn);
    const [roles, setRoles] = useState<Role[]>([]);


    // billing account
    const [billingAccountList, setBillingAccountList] = useState<BillingAccount[]>([]); // list of selected billing account
    const [linkedAccountList, setLinkedAccountList] = useState<BillingAccount[]>([]); // list of linked billing account

    const id = "";
    const { accountId } = useParams();


    // delete dialog
    const [open, setOpen] = useState(false);

    /** If account form is editable */
    const isEdit = !formData.is_default && permissions.includes("CUU003");

    const cancelAction = () => {
        navigate("../.");
    }

    const saveAction = async () => {
        // DEBUG
        // console.log(formData);
        // return;

        let isError = false;
        let newErrColumn = { ...errColumn };

        // validate form, mark incorrect column
        for (const key in errColumn) {
            newErrColumn[key] = formData[key].length == 0; // not empty

            isError = isError || newErrColumn[key];
        }

        setErrColumn(newErrColumn);

        if (isError) {
            alert("🔴 輸入格式錯誤");
            return;
        }


        const requestBody = {
            customer_account: {
                ...formData,
                customer_id: id,
                role_id: roles.filter(item => item.name == formData.role_name)[0].id, // name => id
                status: formData.status == "啟用",  // convert back to boolean
            },
            billing_account_list: billingAccountList
        };

        try {
            const res = await customPUT(`${BACKEND_URL}/api/v1/account/customer/${accountId}`, requestBody)
            const resJson = await res.json();

            if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }

            alert("🟢 Changes saved!");
            navigate(0); // refresh

        } catch (error) {
            console.log(error);
            return;
        }
    }

    const deleteAction = (id: string) => async (event) => {
        try {
            const res = await customDELETE(`${BACKEND_URL}/api/v1/account/customer/${accountId}`);

            if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }

            navigate("../.");

        } catch (error) {
            console.log(error);
            return;
        }
    }


    /**
    * set the value of a single field
    */
    const setFormField = <Key extends keyof Account>(field: Key) => (event: any) => {
        console.log("🖨️ ", event.target.value);
        const value = event.target.value;

        const newFormData = { ...formData };
        newFormData[field] = value;

        setFormData(newFormData);
    }


    const init = async () => {
        // fetch account
        try {
            const res = await customGET(`${BACKEND_URL}/api/v1/account/customer/${accountId}/formData`);
            var resJson = await res.json();

            var account = resJson.customer_account;
            var roleId = account.role_id;

            if (res.status == 404) {
                alert(NOT_FOUND);
                throw new Error;
            } else if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }

            // set billing account
            setLinkedAccountList(resJson.billing_account_list);
            setBillingAccountList(resJson.billing_account_list);

        } catch (error) {
            console.log(error);
            return;
        }

        // fetch all roles
        try {
            const param = new URLSearchParams({
                customer_id: id
            });

            const res = await customGET(`${BACKEND_URL}/api/v1/role/customer?${param}`);
            const resJson = await res.json();

            if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }

            setRoles(resJson);

            // set role name
            setFormData({
                ...account,
                role_name: resJson.filter(item => item.id == roleId)[0].name,
                status: account.status ? "啟用" : "停用",
            });

        } catch (error) {
            console.log(error);
            return;
        }

    }


    useEffect(() => {
        init();
    }, []);

    return (
        // Container (base): wrap Page Title, Form
        <Grid sx={{ width: '100%', height: "70%", padding: "0px" }}>

            {/* go back button */}
            <div style={{ textAlign: "left" }}>
                <Button variant="contained" size="small" startIcon={<ArrowBackIos />} color="primary" disableElevation disableRipple sx={{ margin: "0px", width: "80px", borderRadius: "10px" }} onClick={() => navigate("../.")}> 返回 </Button>
            </div>

            {/* white container */}
            {/* (adapted from Companyinfo.tsx) */}
            <Grid xs={12} sx={{ padding: "20px 20px", background: "white", marginTop: "10px", marginBottom: "10px", borderRadius: "20px" }}>

                <form >
                    <Grid container columnSpacing={5} rowSpacing={1} sx={{ height: "100%", textAlign: "left" }}>
                        <Grid container item xs={12}>
                            <Grid item>
                                <Typography
                                    sx={{ flex: '1 1 auto', textAlign: "left", fontWeight: "bold" }}
                                    id="tableTitle"
                                    component="div"
                                >
                                    編輯使用者
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel shrink sx={inputLabelStyle} >使用者姓名 {<RequiredLabel />}</InputLabel>
                            <TextField
                                value={formData.name}
                                onChange={setFormField("name")}
                                size="small"
                                fullWidth
                                inputProps={inputPropsStyle}
                                sx={inputSX}
                                error={errColumn.name}
                                disabled={!isEdit}
                            />

                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel shrink sx={inputLabelStyle} >Google 登入郵件地址 {<RequiredLabel />}</InputLabel>
                            <TextField
                                value={formData.google_login_mail}
                                onChange={setFormField("google_login_mail")}
                                size="small"
                                fullWidth
                                inputProps={inputPropsStyle}
                                sx={inputSX}
                                error={errColumn.google_login_mail}
                                disabled={!isEdit}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel shrink sx={inputLabelStyle} >角色 {<RequiredLabel />}</InputLabel>
                            <Select
                                value={formData.role_name}
                                onChange={setFormField("role_name")}
                                size="small"
                                fullWidth
                                SelectDisplayProps={SelectDisplayProps}
                                sx={inputSX}
                                error={errColumn.role_name}
                                disabled={!isEdit}
                            >

                                {roles.map((option) => (
                                    <MenuItem key={option.id} value={option.name}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel shrink sx={inputLabelStyle}>狀態</InputLabel>
                            <Select
                                value={formData.status}
                                onChange={setFormField("status")}
                                size="small"
                                fullWidth
                                SelectDisplayProps={SelectDisplayProps}
                                sx={inputSX}
                                disabled={!isEdit}
                            >

                                {enabled.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>


                        {/* bind billing account */}
                        <Grid item xs={12}>
                            <BindBillingAccountTable billingAccountList={billingAccountList} setBillingAccountList={setBillingAccountList} linkedAccountList={linkedAccountList} disabled={false} />
                        </Grid>

                        <Grid item xs={12}>
                            <div style={{ paddingTop: "20px" }}></div>
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel shrink sx={inputLabelStyle} >建立時間</InputLabel>
                            <TextField
                                value={formData.create_datetime == null ? "-" : (new Date(formData.create_datetime)).toLocaleDateString('zh', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                    hour12: false,
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit',
                                })}
                                size="small"
                                fullWidth
                                inputProps={inputPropsStyle}
                                sx={inputSX}
                                disabled />
                        </Grid>


                        <Grid item xs={12}>
                            <InputLabel shrink sx={inputLabelStyle} >修改時間</InputLabel>
                            <TextField
                                value={formData.update_datetime == null ? "-" : (new Date(formData.update_datetime)).toLocaleDateString('zh', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                    hour12: false,
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit',
                                })}
                                size="small"
                                fullWidth
                                inputProps={inputPropsStyle}
                                sx={inputSX}
                                disabled
                            />
                        </Grid>
                    </Grid>
                </form>

                <Grid xs={12} sx={{ width: '100%', display: "flex", justifyContent: "flex-end", padding: "30px 0px 0px" }}>
                    {
                        // hidden buttons if default
                        !formData.is_default &&
                        <>
                            {
                                permissions.includes("CUU004") &&
                                <Button variant="contained" color="error" size="small" sx={{ margin: "auto 10px" }} onClick={() => setOpen(true)}> 刪除 </Button>
                            }
                            {
                                permissions.includes("CUU003") &&
                                <>
                                    <Button variant="contained" color="inherit" size="small" sx={{ margin: "auto 10px" }} onClick={cancelAction}> 取消 </Button>
                                    <Button variant="contained" color="primary" size="small" sx={{ margin: "auto 10px" }} onClick={saveAction}> 儲存 </Button>
                                </>
                            }
                        </>
                    }
                </Grid>
            </Grid>


            <Dialog
                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogTitle>{"確定刪除？"}</DialogTitle>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}> 取消 </Button>
                    <Button onClick={deleteAction(accountId)}
                    >
                        確定
                    </Button>
                </DialogActions>
            </Dialog>

        </Grid>
    );
}


export default AccountForm;