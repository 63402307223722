import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

// store
import { useSelector, useDispatch } from 'react-redux';
import { selecter } from '../../store/CusInvoiceTableStore'

// mui
import { Autocomplete, Box, Button, Grid, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import moment from 'moment';

// mui icons
import { EditNoteOutlined } from '@mui/icons-material';

// types
import { InvoiceRow } from '../../types/Invoice.types';

// utils
import { customGET } from '../../utils/customFetch';

// misc
import { SERVER_ERROR } from '../../shared/errorMessage';


const BACKEND_URL = process.env.REACT_APP_BACKEND_URL


// css for search bar (Mui Autocomplete)
const InputPropsStyle = {
    style: {
        height: "12px",
        fontSize: "12px",
        borderRadius: "5px",    // limit gray area within input box
    }
}; // add borderRadius to contrain background coloring within text box


const inputSX = {
    // How to change height of Autocomplete?
    // .MuiAutocomplete-input
    // ref: https://mui.com/material-ui/api/autocomplete/#Autocomplete-css-input

    // .MuiInputBase-input
    // ref: https://aguidehub.com/blog/2023-01-12-how-to-change-mui-autocomplete-height-in-react-js/?expand_article=1

    ".MuiAutocomplete-input": {
        height: "12px",
        fontSize: "12px",
        borderRadius: "5px",
    },
    width: "200px"
};



// columns of table header
const headCells = [
    {
        id: "invoice_no",
        label: "發票號碼",
        width: "20%"
    },
    {
        id: "invoice_month",
        label: "發票月份",
        width: "20%"
    },
    {
        id: "contract_id",
        label: "訂單編號",
        width: "20%"
    },
    // {
    //     id: "billing_account_id",
    //     label: "帳號",
    //     width: "20%"
    // },
    {
        id: "object_name",
        label: "統一發票",
        width: "40%"
    },
];

/**
 * Table header
 */
function InvoiceTableHead() {

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={"left"}
                        sx={{ fontSize: "12px" }}
                        width={headCell.width}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function CusInvoiceTable() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [rows, setRows] = useState<InvoiceRow[]>([]);

    // store
    const paramsStore = useSelector(selecter)
    const dispatch = useDispatch()


    // search key (selected item)
    const [searchKeyContract, setSearchKeyContract] = useState(
        paramsStore.params && paramsStore.params['contract_id']? 
        { 
            id: paramsStore.params['contract_id'],
            label: paramsStore.params['contract_id']
        } :
        null
    );
    const [searchKeyBilling, setSearchKeyBilling] = useState(null);

    // search input (typed input)
    const [searchInputContract, setSearchInputContract] = useState("");
    const [searchInputBilling, setSearchInputBilling] = useState("");


    // options
    const [contractOptions, setContractOptions] = useState([]);
    const [billingOptions, setBillingOptions] = useState([]);

    // disable search bar
    const [disableContract, setDisableContract] = useState(false);
    const [disableBilling, setDisableBilling] = useState(false);

    // date filter
    // initialize as current month (first day)
    // note: Date().getMonth() is 0-based
    const [startTime, setStartTime] = useState(
        paramsStore.params? 
        dayjs(paramsStore.params['start_time']) :
        dayjs(moment().local().format("yyyy-MM"))
    );
    const [endTime, setEndTime] = useState(
        paramsStore.params? 
        dayjs(paramsStore.params['end_time']).add(-1, 'M') :
        dayjs(moment().local().format("yyyy-MM"))
    );

    // pagination
    const [totalPage, setTotalPage] = useState(0);
    const [page, setPage] = useState(
        paramsStore.params? 
        paramsStore.params['page'] + 1 :
        1
    ); // start from 1
    const [pageSize, setPageSize] = useState(
        paramsStore.params? 
        paramsStore.params['pageSize'] :
        10
    );


    const renderEditButton = (id: string) => {
        return (
            <Tooltip title={"edit"} placement="right">
                <IconButton
                    size="small"
                    color="primary"
                    sx={{ padding: 0 }}
                    onClick={() => navigate(`${id}`, {
                        state: {
                            isEdit: true
                        }
                    })}
                >
                    {<EditNoteOutlined />}
                </IconButton>
            </Tooltip>
        );
    }

    /**
     * event handler when a row is clicked
     */
    const rowOnClick = (id: string) => (event: any) => {
        navigate(`${id}`);
    }


    /**  key changed */
    const init = async () => {
        // update contract & billing options
        const params = new URLSearchParams({
            customer_id: id
        });


        try {
            const res = await customGET(`${BACKEND_URL}/api/v1/billing-account/customer?${params}`);
            const resJson = await res.json();

            if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }

            setBillingOptions(resJson.map(item => {
                return {
                    id: item.id,
                    label: item.billing_name,
                }
            }));
        } catch (error) {
            console.log(error);
            return;
        }

        try {
            const res = await customGET(`${BACKEND_URL}/api/v1/contract/customer?${params}`);
            const resJson = await res.json();

            if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }

            setContractOptions(resJson.map(item => {
                return {
                    id: item.id,
                    label: item.id,
                }
            }));
        } catch (error) {
            console.log(error);
            return;
        }
    }


    /**  key changed */
    const searchKeyContractOnChange = async (event, value) => {
        setSearchKeyContract(value);

        // reset billing search bar whenever search key contract changes
        setSearchKeyBilling(null);
        setSearchInputBilling("");


        if (value == null) {
            return;
        }

        const params = new URLSearchParams({
            contract_id: value.id
        });

        try {
            // update billing options
            let res = await customGET(`${BACKEND_URL}/api/v1/billing-account/contract?${params}`);
            let resJson = await res.json();

            if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }

            setBillingOptions(resJson.map(item => {
                return {
                    id: item.id,
                    label: item.billing_name,
                }
            }));
        } catch (error) {
            console.log(error);
            return;
        }
    }


    /**  input changed */
    const searchInputContractOnChange = async (event, value) => {
        setSearchInputContract(value);
    }


    /**  key changed */
    const searchKeyBillingOnChange = async (event, value) => {
        setSearchKeyBilling(value);

        if (value == null) {
            setDisableContract(false);
            return;
        }

        // when search by customer + billing, disable contract
        if (searchInputContract.length == 0)
            setDisableContract(true);
    }


    /**  input changed */
    const searchInputBillingOnChange = async (event, value) => {
        setSearchInputBilling(value);
    }


    /**
     * search based on either customer_id, contract_id or billing_account_id
     */
    const fetchRows = async (page: number, pageSize: number) => {
        const _params = {};
        _params["customer_id"] = id;

        // if (searchKeyBilling)
        //     _params["billing_account_id"] = searchKeyBilling.id;
        if (searchKeyContract)
            _params["contract_id"] = searchKeyContract.id;

        _params["start_time"] = startTime.toISOString();
        _params["end_time"] = endTime.add(1, 'M').toISOString();     // move forward one month (exclusive condition)

        _params["page"] = page - 1; // 1-based to 0-based
        _params["pageSize"] = pageSize;

        const params = new URLSearchParams(_params);

        try {
            // fetch search results
            const res = await customGET(`${BACKEND_URL}/api/v1/invoice/search?${params}`);
            const resJson = await res.json();

            if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }

            dispatch({
                type: "cusInvoiceTable/setParams",
                payload: { params: _params }
            })
            setRows(resJson);
            setTotalPage(resJson ? Math.ceil(resJson[0].total_rows / pageSize) : 0);
        } catch (error) {
            console.log(error);
            return;
        }

    }

    function invoice_month_moment(invoice_month: number): moment.Moment {
        return moment(invoice_month).local();
    }

    const clearSearchBar = () => {
        // reset search bars
        setSearchKeyContract(null);
        setSearchKeyBilling(null);
        setSearchInputContract("");
        setSearchInputBilling("");

        init(); // reset search bars options

        // enable
        setDisableContract(false);
        setDisableBilling(false);
    }


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const search = () => {
        // reset to 1st page
        setPage(1);

        fetchRows(1, pageSize);
    }

    useEffect(() => {
        init();
    }, []);

    // fetch new rows when page changes
    useEffect(() => {
        fetchRows(page, pageSize);
    }, [page, pageSize]);


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"zh-cn"}>
            {/* white container (start) */}

            {/* date filter */}
            <Toolbar sx={{ borderRadius: "20px 20px 0px 0px", background: "white", padding: "20px 0px 10px", margin: "10px 0px 0px" }} variant="dense">

                <Typography
                    align='center'
                    sx={{ fontWeight: "bold", fontSize: "12px", padding: "0px 10px 0px 0px" }}
                    component="div"
                >
                    起始月份
                </Typography>

                <DatePicker
                    value={startTime}
                    onChange={(value) => setStartTime(value)}
                    openTo="month"
                    format="YYYY年MM月"
                    sx={{ width: "200px" }}
                    slotProps={{
                        textField: {
                            size: "small",
                            inputProps: InputPropsStyle,
                        }

                    }}
                    views={['year', 'month']}
                />

                <Typography
                    align='center'
                    sx={{ fontWeight: "bold", fontSize: "12px", padding: "0px 10px 0px 45px" }}
                    component="div"
                >
                    結束月份
                </Typography>

                <DatePicker
                    value={endTime}
                    onChange={(value) => setEndTime(value)}
                    openTo="month"
                    format="YYYY年MM月"
                    sx={{ width: "200px" }}
                    slotProps={{
                        textField: {
                            size: "small",
                            inputProps: InputPropsStyle,
                        }
                    }}
                    views={['year', 'month']}
                />
            </Toolbar>

            {/* search bar */}
            <Toolbar sx={{ background: "white" }} variant="dense">
                {/* note: justify (horizontal), align (vertical) */}
                {/* <Grid item xs={0.7} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}> */}

                <Typography
                    sx={{ fontWeight: "bold", fontSize: "12px", padding: "0px 10px 0px 0px" }}
                    component="div"
                >
                    訂單編號
                </Typography>

                <Autocomplete
                    value={searchKeyContract}
                    onChange={searchKeyContractOnChange}
                    inputValue={searchInputContract}
                    onInputChange={searchInputContractOnChange}
                    options={contractOptions}
                    size="small"
                    autoComplete={false}
                    sx={inputSX}
                    ListboxProps={{ style: { fontSize: "14px" } }}      // dropdown item
                    renderInput={(params) => <TextField {...params} />}
                    disabled={disableContract}
                ></Autocomplete>


                {/* <Typography
                    sx={{ fontWeight: "bold", fontSize: "12px", padding: "0px 10px 0px 70px" }}
                    component="div"
                >
                    帳號
                </Typography>


                <Autocomplete
                    value={searchKeyBilling}
                    onChange={searchKeyBillingOnChange}
                    inputValue={searchInputBilling}
                    onInputChange={searchInputBillingOnChange}
                    options={billingOptions}
                    size="small"
                    autoComplete={false}
                    sx={inputSX}
                    ListboxProps={{ style: { fontSize: "14px" } }}      // dropdown item
                    renderInput={(params) => <TextField {...params} />}
                    disabled={disableBilling}
                ></Autocomplete> */}


                <Button variant="contained" size="small" disableElevation color="primary" sx={{ borderRadius: "10px", fontSize: "10px", padding: "5px", margin: "0px 10px 0px 30px" }} onClick={() => search()}> 搜尋 </Button>

                <Button variant="contained" size="small" disableElevation color='error' sx={{ borderRadius: "10px", fontSize: "10px", padding: "5px", margin: "0px" }} onClick={clearSearchBar}> 清除輸入 </Button>

            </Toolbar>


            <TableContainer sx={{ height: "60%", padding: "0px", borderRadius: "0px 0px 20px 20px", background: "white" }}>
                <Table
                    stickyHeader
                    aria-labelledby="tableTitle"
                    size="small" // control row density
                >
                    <InvoiceTableHead />

                    <TableBody>
                        {rows.map((row, index) => {

                            return (
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={row.id}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <TableCell
                                        component="th"
                                        id={`table-row-${index}`}
                                        scope="row"
                                        align="left"
                                        sx={{ fontSize: "12px" }}
                                        width="20%"
                                        onClick={rowOnClick(row.id.toString())}
                                    >
                                        {row.invoice_no != null ? row.invoice_no : "未開立發票"}
                                    </TableCell>

                                    <TableCell
                                        align="left"
                                        sx={{ fontSize: "12px" }}
                                        width="20%"
                                        onClick={rowOnClick(row.id.toString())}
                                    >
                                        {/* month: 0 -> Jan, 1-> Feb, etc. */}
                                        {`${invoice_month_moment(row.invoice_month).format('yyyy')}年${(invoice_month_moment(row.invoice_month)).format('MM')}月`}
                                    </TableCell>

                                    <TableCell
                                        align="left"
                                        sx={{ fontSize: "12px" }}
                                        width="20%"
                                        onClick={rowOnClick(row.id.toString())}
                                    >
                                        {row.contract_id}
                                    </TableCell>

                                    {/* <TableCell
                                        align="left"
                                        sx={{ fontSize: "12px" }}
                                        width="20%"
                                        onClick={rowOnClick(row.id.toString())}
                                    >
                                        {row.billing_name}
                                    </TableCell> */}

                                    <TableCell
                                        align="left"
                                        sx={{ fontSize: "12px" }}
                                        width="40%"
                                        onClick={rowOnClick(row.id.toString())}
                                    >
                                        {row.object_name ? row.object_name.split("/")[2] : "未開立發票"}
                                    </TableCell>

                                </TableRow>
                            );
                        })}

                    </TableBody>
                </Table>
            </TableContainer>
            {/* white container (end) */}

            {/* pagination control */}
            <Pagination count={totalPage} page={page} showFirstButton showLastButton onChange={handleChangePage} shape="rounded" sx={{ display: "flex", justifyContent: "flex-end", padding: "10px 0px" }} />

        </LocalizationProvider>
    );
}

export default CusInvoiceTable;
