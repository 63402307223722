import { createSlice } from '@reduxjs/toolkit'

const cusInvoiceTable = createSlice({
    name: 'cusInvoiceTable',
    initialState: {
        value: { params: null }
    },
    //actions
    reducers: {
        setParams: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { setParams } = cusInvoiceTable.actions
export const selecter = (state) => state.cusInvoiceTable.value
export default cusInvoiceTable.reducer