import { useEffect, useState, useRef } from "react";
import { Navigate } from "react-router";
import { useSearchParams } from "react-router-dom";

// mui
import { Alert, Box, Button, Grid, Snackbar, TextField, Typography } from "@mui/material";


const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

type Loggedin = "init" | "login" | "logout";

function Login() {
    const [loggedin, setLoggedin] = useState<Loggedin>("init");
    const [searchParams, setSearchParams] = useSearchParams();

    // login error snakebar
    const [open, setOpen] = useState(false);

    let loginFormRef = useRef(null);

    const loginFormSubmit = () => {
        loginFormRef.current.method = "POST";
        loginFormRef.current.action = `${BACKEND_URL}/api/v1/auth/login`;
        loginFormRef.current.submit();
    }

    /**
     * check JWT to determine login or logout state
     */
    const auth = async () => {
        // initial state
        // valid -> login
        // expire or not exist -> logout
        const res = await fetch(`${BACKEND_URL}/api/v1/auth/refreshtoken`, {
            credentials: "include",
        });

        if (res.status == 200) {
            setLoggedin("login");
            return;
        } else {
            // refresh token expired or other error, require login
            setLoggedin("logout");
            if (searchParams.has("error")) {
                setOpen(true);
            }
            return;
        }

    }

    useEffect(() => {
        auth();
    }, []);


    if (loggedin == "init")
        return (<></>);     // blank page

    else if (loggedin == "login")
        return (<Navigate to="/" replace={true} />);

    else
        return (
            <Box sx={{ background: "#F4F7FE", height: "100vh", width: "100vw", textAlign: "center" }}>
                <Box sx={{ height: "95%", paddingTop: "30vh" }}>
                    <img src="/epicloud.png" alt="epicloud icon" style={{ width: "200px" }}></img>

                    <Typography sx={{ width: "100%", fontSize: "30px", fontWeight: "bold", color: "#2B3674" }}>
                        Google Cloud 帳務平台
                    </Typography>

                    {/* version */}
                    {/* <Typography sx={{ width: "100%", fontSize: "20px", fontWeight: "bold", color: "#2380C3", marginBottom: "50px" }}>
                        <span style={{ fontSize: "12px", fontWeight: "normal", color: "#2B3674" }}>v.1.0.0</span>
                    </Typography> */}

                    <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
                        <Grid item xs={12} md={3} style={{ maxWidth: 350 }}>
                            <Box id="loginForm" ref={loginFormRef} component="form" sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                                <Typography sx={{ fontWeight: "bold", color: "#2B3674" }}>
                                    使用驗證碼登入
                                </Typography>
                                <TextField
                                    id="email"
                                    required
                                    label="Email"
                                    name="email"
                                    variant="outlined"
                                    color="info"
                                    size="small"
                                    sx={{ width: 300 }}
                                />
                                <TextField
                                    id="password"
                                    label="驗證碼"
                                    name="password"
                                    type="password"
                                    variant="outlined"
                                    color="info"
                                    size="small"
                                    sx={{ width: 300 }}
                                />
                                <Button size="small" variant="contained" disableElevation sx={{ width: "300px", borderRadius: "20px", textTransform: "none" }}
                                    onClick={loginFormSubmit}>
                                    {"驗證碼登入"}
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={3} style={{ maxWidth: 350 }}>
                            <Button size="small" variant="contained" disableElevation sx={{ width: "150px", borderRadius: "20px", textTransform: "none" }}
                                    onClick={() => window.location.href = `${BACKEND_URL}/oauth2/authorization/google`}>
                                    {"透過 Google 登入"}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

                <Typography sx={{ width: "100%", fontSize: "12px" }}>
                    Copyright © 2023 Epic Cloud. All Rights Reserved.
                </Typography>

                {/* error snakebar */}
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <Alert severity="error" variant="filled">登入失敗</Alert>
                </Snackbar>
            </Box>
        )
}



export default Login;