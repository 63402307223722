import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

// mui
import { Box, IconButton, Tooltip } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

// mui icons
import { EditNoteOutlined } from '@mui/icons-material';

// types
import { BillingAccount } from '../../types/Contract.types';

// components
import TableToolbar from './TableToolbar';

// utils
import { customGET } from '../../utils/customFetch';

// misc
import { SERVER_ERROR } from '../../shared/errorMessage';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL


// columns of table header
const headCells = [
    {
        id: "billing_name",
        label: "專案名稱",
        width: "20%",
    },
    {
        id: "id",
        label: "專案ID",
        width: "20%"
    },
    {
        id: "status",
        label: "狀態",
        width: "10%",
    },
    {
        id: "customer_account",
        label: "綁定的使用者",
        width: "30%",
    },
];


function BillingAccountTable() {
    const navigate = useNavigate();
    const [rows, setRows] = useState<BillingAccount[]>([]);
    const { id } = useParams();

    const init = async () => {
        const params = new URLSearchParams({
            customer_id: id
        });

        try {
            const res = await customGET(`${BACKEND_URL}/api/v1/billing-account/customer?${params}`);
            const billingAccountList: Array<BillingAccount> = await res.json();
            console.log("✅ fetch");

            const _rows = billingAccountList.map(async (item) => {
                const res = await customGET(`${BACKEND_URL}/api/v1/account/customer/${item.customer_account_id}`);
                const account = await res.json();
                const name = account.google_login_mail;

                // skip error
                if (res.status != 200) {
                    return item;
                }

                return {
                    ...item,
                    customer_account_id: name,
                }
            })

            setRows(await Promise.all(_rows));

            if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }

        } catch (error) {
            console.log(error);
            return;
        }

    }

    const renderEditButton = (id: string) => {
        return (
            <Tooltip title={"edit"} placement="right">
                <IconButton
                    size="small"
                    color="primary"
                    sx={{ padding: 0 }}
                    onClick={() => navigate(`${id}`, {
                        state: {
                            isEdit: true
                        }
                    })}
                >
                    {<EditNoteOutlined />}
                </IconButton>
            </Tooltip>
        );
    }

    /**
     * event handler when a row is clicked
     */
    const rowOnClick = (id: string) => (event: any) => {
        navigate(`${id}`, {
            state: {
                isEdit: false
            }
        });
    }

    useEffect(() => {
        init();
    }, []);


    return (
        <div style={{ padding: "10px 0px 10px" }}>
            {/* white container (start) */}
            <TableToolbar />

            <TableContainer sx={{ height: "70%", padding: "0px", borderRadius: "0px 0px 20px 20px", background: "white" }}>
                <Table
                    stickyHeader
                    aria-labelledby="tableTitle"
                    size="small" // control row density
                >
                    {/* table header */}
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align={"left"}
                                    sx={{ fontSize: "12px" }}
                                    width={headCell.width}
                                >
                                    {headCell.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    {/* table body */}
                    <TableBody>
                        {rows.map((row, index) => {

                            return (
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={row.id}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <TableCell
                                        component="th"
                                        id={`table-row-${index}`}
                                        scope="row"
                                        align="left"
                                        width="20%"
                                        sx={{ fontSize: "12px" }}
                                        onClick={rowOnClick(row.id)}
                                    >
                                        {row.billing_name}
                                    </TableCell>

                                    <TableCell
                                        align="left"
                                        width="20%"
                                        sx={{ fontSize: "12px" }}
                                        onClick={rowOnClick(row.id)}
                                    >
                                        {row.id}
                                    </TableCell>

                                    <TableCell
                                        align="left"
                                        width="10%"
                                        sx={{ fontSize: "12px" }}
                                        onClick={rowOnClick(row.id)}
                                    >
                                        {row.status ? "啟用" : "停用"}
                                    </TableCell>

                                    <TableCell
                                        align="left"
                                        width="30%"
                                        sx={{ fontSize: "12px" }}
                                        onClick={rowOnClick(row.id)}
                                    >
                                        {row.customer_account_id}
                                    </TableCell>

                                </TableRow>
                            );
                        })}

                    </TableBody>
                </Table>
            </TableContainer>
            {/* white container (end) */}
        </div>
    );
}

export default BillingAccountTable;
