/**
 * form template
 */

import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';

// mui
import { Box, Button, Checkbox, FormControlLabel, InputLabel, MenuItem, PaletteColorOptions, Paper, Select, ThemeProvider, Typography, createTheme } from '@mui/material';
import { SelectChangeEvent } from '@mui/material';
import { FormControl, TextField } from '@mui/material';
import { Grid } from '@mui/material';

// mui icons
import { Edit } from '@mui/icons-material';

// React Hook Form ref: https://www.react-hook-form.com/
// import { useForm, Controller } from "react-hook-form"; (optional)

// types
import { Customer } from '../../../types/Customer.types';

// css
import { inputPropsStyle, inputSX, SelectDisplayProps, inputLabelStyle } from '../../../css/formStyle';

// utils
import { customGET, customPUT } from '../../../utils/customFetch';

// misc
import { currencies, cities, township, enabled } from '../../../shared/formSelectOptions';
import { permissions } from '../../../localStorage/permission';
import { NOT_FOUND, SERVER_ERROR } from '../../../shared/errorMessage';


// helper text
const RequiredLabel = () => (<span><span style={{ color: "red" }}>*</span> <span style={{ color: "grey" }}>(必填)</span></span>);

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL


const initialFormData: Customer = {
    id: "",
    name: "",
    status: "啟用",
    // city: "",
    // township: "",
    zipcode: "",
    invoice_address: "",
    address: "",
    tw_tax_no: "",
    currency: 'TWD',
    industry_type: "",
    contact_name: "",
    contact_tel: "",
    contact_ext: "",
    contact_phone_no: "",
    contact_emails: "",
    contact_cc_emails: "",
    domain: "",
}

// columns that require validation
const initErrColumn = {
    name: false,
    // city: false,
    // township: false,
    zipcode: false,
    invoice_address: false,
    tw_tax_no: false,
    contact_name: false,
    contact_emails: false,
}

function CompanyInfo() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [isEdit, setIsEdit] = useState(permissions.includes("CUS004") && state ? state.isEdit : false);
    const [formData, setFormData] = useState<Customer>(initialFormData); // render current form state
    const [oldFormData, setOldFormData] = useState<Customer>(initialFormData); // store original form state
    const [townshipList, setTownshipList] = useState<Array<string>>([]); // township select list
    const [zipcodeList, setZipcodeList] = useState<Array<string>>([]);  // an array for internal use, townshipList and this list has one-to-one mapping
    // const { handleSubmit, control, register, formState: { errors } } = useForm(); // React Hook Form
    const [errColumn, setErrColumn] = useState<any>(initErrColumn);
    const [checkedAddress, setCheckedAddress] = useState(false);    // checkbox controll invoice_address & address

    const { id } = useParams();

    console.log("⛔️ CompanyInfo rendered...");

    const cancelAction = () => {
        // restore original data
        setFormData(oldFormData);
        setIsEdit(false);
        setCheckedAddress(false);
        navigate(".", { replace: true }); // clear location.state
    }

    const saveAction = async () => {
        // DEBUG
        // alert(JSON.stringify(formData));
        // return;

        let isError = false;
        let newErrColumn = { ...errColumn };

        // validate form, mark incorrect column
        for (const key in errColumn) {
            if (key == "tw_tax_no")
                newErrColumn[key] = !(new RegExp('^[0-9]{8}$|^$')).test(formData[key]);  // regex
            else
                newErrColumn[key] = formData[key].length == 0; // not empty

            isError = isError || newErrColumn[key];
        }

        setErrColumn(newErrColumn);

        if (isError) {
            alert("🔴 輸入格式錯誤");
            return;
        }

        const requestBody = {
            ...formData,
            status: (formData.status == "啟用"),
            address: checkedAddress ? formData.invoice_address : formData.address,
            contact_tel: formData.contact_tel + "#" + formData.contact_ext
        };

        try {
            const res = await customPUT(`${BACKEND_URL}/api/v1/customer`, requestBody);

            if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }
        } catch (error) {
            console.log(error);
            return;
        }


        alert("🟢 Changes saved!");
        navigate(".", { replace: true }); // clear location.state
        navigate(0); // refresh page
    }

    // const citiesOnselect = (event: SelectChangeEvent<string>) => {
    //     const city = event.target.value;
    //     setTownshipList(township[city][0]); // this will affect the "township" select options
    //     setZipcodeList(township[city][1]);
    //     setFormData({ ...formData, "city": city, "township": "", "zipcode": "" }); // reset township & zipcode
    // }

    // const townshipOnselect = (event: SelectChangeEvent<string>) => {
    //     const selectedTownship = event.target.value;
    //     const idx = townshipList.indexOf(selectedTownship);
    //     setFormData({ ...formData, "township": selectedTownship, "zipcode": zipcodeList[idx] }); // this will automatically set "zipcode" column
    // }

    /**
    * set the value of a single field
    */
    const setFormField = <Key extends keyof Customer>(field: Key) => (event: any) => {
        console.log("🖨️ ", event.target.value);
        const newFormData = { ...formData };
        newFormData[field] = event.target.value as Customer[Key];

        setFormData(newFormData);
    }

    /** initialize */
    const init_formData = async () => {

        try {
            // fetch form data
            const res = await customGET(`${BACKEND_URL}/api/v1/customer`);
            var data = await res.json();
            console.log(data);

            if (res.status == 404) {
                alert(NOT_FOUND);
                throw new Error;
            } else if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }

        } catch (error) {
            console.log(error);
            return;
        }

        data.status = data.status ? "啟用" : "停用"; // convert boolean to string
        const [tel, ext] = data.contact_tel.split("#");
        data.contact_tel = tel;
        data.contact_ext = ext;
        console.log("✅ ", data);

        // set both current and old FormData
        setFormData({ ...data });
        setOldFormData({ ...data });

        // extra handling: townshipList, zipcodeList (select option)
        // const township_zipcode_matrix = township[data.city];
        // setTownshipList(township_zipcode_matrix[0]);
        // setZipcodeList(township_zipcode_matrix[1]);
    }

    useEffect(() => {
        init_formData();
    }, []);

    return (
        // white container
        <Grid xs={12} sx={{ width: '100%', padding: "20px 20px", background: "white", marginTop: "10px", marginBottom: "10px", borderRadius: "20px" }}>

            {/* Title */}
            <Grid xs={12} sx={{ display: "flex", justifyContent: "flex-start", padding: "0px 0px 10px" }}>
                {/* show edit button when not in edit mode */}
                {
                    permissions.includes("CUS004") && !isEdit
                    && <Button variant="contained" size="small" startIcon={<Edit />} color="primary" sx={{ margin: "0px" }} onClick={() => setIsEdit(true)}> 編輯 </Button>
                }
            </Grid>

            <form >
                <Grid container columnSpacing={5} rowSpacing={1} sx={{ height: "100%", textAlign: "left" }}>
                    <Grid container item xs={12}>
                        <Grid item>
                            <Typography
                                sx={{ flex: '1 1 auto', textAlign: "left", fontWeight: "bold" }}
                                id="tableTitle"
                                component="div"
                            >
                                基本資料
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel shrink sx={inputLabelStyle} >客戶編號 </InputLabel>
                        <TextField
                            // {...register("id", { required: true, value: formData.id })}
                            value={formData.id}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            disabled
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel shrink sx={inputLabelStyle} >客戶名稱 {isEdit && <RequiredLabel />}</InputLabel>
                        <TextField
                            // {...register("name", { required: true, value: formData.name })}
                            value={formData.name}
                            onChange={setFormField("name")}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            disabled={!isEdit}
                            sx={inputSX}
                            error={errColumn.name}
                        />
                    </Grid>
                    {/* <input type="text" id="fname" name="fname" style={{background : "red", borderRadius: "25px"}}></input> */}
                    <Grid item xs={12}>
                        <InputLabel shrink sx={inputLabelStyle}>客戶狀態</InputLabel>
                        <Select
                            // {...register("status", { required: true, value: formData.status })}
                            value={formData.status}
                            onChange={setFormField("status")}
                            size="small"
                            fullWidth
                            SelectDisplayProps={SelectDisplayProps}
                            disabled={!isEdit}
                            sx={inputSX}
                        >

                            {enabled.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>

                    {/* <Grid item xs={4}>
                        <InputLabel shrink sx={inputLabelStyle}>縣市 {isEdit && <RequiredLabel />}</InputLabel>
                        <Select
                            // {...register("city", { required: true, value: formData.city })}
                            value={formData.city}
                            size="small"
                            fullWidth
                            SelectDisplayProps={SelectDisplayProps}
                            disabled={!isEdit}
                            sx={inputSX}
                            onChange={citiesOnselect}
                            error={errColumn.city}
                        >
                            {cities.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel shrink sx={inputLabelStyle}>鄉鎮市區 {isEdit && <RequiredLabel />}</InputLabel>
                        <Select
                            // {...register("township", { required: true, value: formData.township })}
                            value={formData.township}
                            size="small"
                            fullWidth
                            SelectDisplayProps={SelectDisplayProps}
                            disabled={!isEdit}
                            sx={inputSX}
                            onChange={townshipOnselect}
                            error={errColumn.township}
                        >
                            {townshipList.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid> */}

                    <Grid item xs={2}>
                        <InputLabel shrink sx={inputLabelStyle}>郵遞區號 {isEdit && <RequiredLabel />}</InputLabel>
                        <TextField
                            // {...register("zipcode", { required: true, value: formData.zipcode })}
                            value={formData.zipcode}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            disabled={!isEdit}
                            sx={inputSX}
                            onChange={setFormField("zipcode")}
                            error={errColumn.zipcode}
                        />
                    </Grid>

                    <Grid item xs={10}>
                        <InputLabel shrink sx={inputLabelStyle}>發票地址 {isEdit && <RequiredLabel />}</InputLabel>
                        <TextField
                            // {...register("address", { required: true, value: formData.address })}
                            value={formData.invoice_address}
                            onChange={setFormField("invoice_address")}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            disabled={!isEdit}
                            sx={inputSX}
                            error={errColumn.invoice_address}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel shrink sx={inputLabelStyle}>聯絡地址
                            {
                                isEdit &&
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size='small'
                                            checked={checkedAddress}
                                            onChange={(event) => {
                                                const checked = event.target.checked;
                                                setCheckedAddress(checked);
                                            }}
                                            sx={{
                                                padding: "0px 5px 0px",
                                                margin: "0px"
                                            }}
                                        />
                                    }
                                    label="與發票地址相同"
                                    sx={{
                                        padding: "0px 10px 0px",
                                        margin: "0px",
                                        "& .MuiFormControlLabel-label": { fontSize: "14px" },
                                        '& .MuiSvgIcon-root': { fontSize: "12px" }
                                    }}
                                />
                            }
                        </InputLabel>
                        <TextField
                            // {...register("address", { required: true, value: formData.address })}
                            value={checkedAddress ? formData.invoice_address : formData.address}
                            onChange={setFormField("address")}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            disabled={!isEdit}
                            sx={inputSX}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel shrink sx={inputLabelStyle}>統一編號</InputLabel>
                        <TextField
                            // {...register("tw_tax_no", { required: true, value: formData.tw_tax_no })}
                            value={formData.tw_tax_no}
                            onChange={setFormField("tw_tax_no")}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            disabled={!isEdit}
                            sx={inputSX}
                            error={errColumn.tw_tax_no}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <InputLabel shrink sx={inputLabelStyle}>使用幣別</InputLabel>
                        <Select
                            // {...register("currency", { required: true, value: formData.currency })}
                            value={formData.currency}
                            onChange={setFormField("currency")}
                            size="small"
                            fullWidth
                            SelectDisplayProps={SelectDisplayProps}
                            disabled={!isEdit} sx={inputSX}>
                            {currencies.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={8}>
                        <InputLabel shrink sx={inputLabelStyle}>行業別</InputLabel>
                        <TextField
                            // {...register("description", { required: true, value: formData.description })}
                            value={formData.industry_type}
                            onChange={setFormField("industry_type")}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            disabled={!isEdit}
                            sx={inputSX} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel shrink sx={inputLabelStyle}>客戶domain</InputLabel>
                        <TextField
                            // {...register("description", { required: true, value: formData.description })}
                            value={formData.domain}
                            onChange={setFormField("domain")}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            disabled={!isEdit}
                            sx={inputSX} />
                    </Grid>


                    <Grid item xs={12}>
                        <Typography
                            sx={{ flex: '1 1 auto', textAlign: "left", fontWeight: "bold", padding: "20px 0px 0px" }}
                            id="tableTitle"
                            component="div"
                        >
                            主要對帳聯絡人 (財會)
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel shrink sx={inputLabelStyle}>姓名 {isEdit && <RequiredLabel />}</InputLabel>
                        <TextField
                            // {...register("contact_name", { required: true, value: formData.contact_name })}
                            value={formData.contact_name}
                            onChange={setFormField("contact_name")}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            disabled={!isEdit}
                            sx={inputSX}
                            error={errColumn.contact_name}
                        />
                    </Grid>

                    <Grid item xs={8}>
                        <InputLabel shrink sx={inputLabelStyle}>聯絡人市話號碼</InputLabel>
                        <TextField
                            // {...register("contact_tel", { required: true, value: formData.contact_tel })}
                            value={formData.contact_tel}
                            onChange={setFormField("contact_tel")}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            disabled={!isEdit}
                            sx={inputSX}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel shrink sx={inputLabelStyle}>分機</InputLabel>
                        <TextField
                            // {...register("contact_tel", { required: true, value: formData.contact_tel })}
                            value={formData.contact_ext}
                            onChange={setFormField("contact_ext")}
                            size="small"
                            fullWidth
                            disabled={!isEdit}
                            inputProps={inputPropsStyle}
                            sx={inputSX}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel shrink sx={inputLabelStyle}>聯絡人手機號碼 </InputLabel>
                        <TextField
                            // {...register("contact_phone_no", { required: true, value: formData.contact_phone_no })}
                            value={formData.contact_phone_no}
                            onChange={setFormField("contact_phone_no")}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            disabled={!isEdit}
                            sx={inputSX} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel shrink sx={inputLabelStyle}>通知郵件地址 {isEdit && <RequiredLabel />}</InputLabel>
                        <TextField
                            // {...register("contact_emails", { required: true, value: formData.contact_emails })}
                            value={formData.contact_emails}
                            onChange={setFormField("contact_emails")} size="small"
                            fullWidth inputProps={inputPropsStyle}
                            disabled={!isEdit}
                            sx={inputSX}
                            error={errColumn.contact_emails}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel shrink sx={inputLabelStyle}>通知郵件地址副本 </InputLabel>
                        <TextField
                            // {...register("contact_cc_emails", { required: true, value: formData.contact_cc_emails })}
                            value={formData.contact_cc_emails}
                            onChange={setFormField("contact_cc_emails")}
                            size="small"
                            fullWidth
                            inputProps={inputPropsStyle}
                            disabled={!isEdit}
                            sx={inputSX} />
                    </Grid>

                </Grid>
            </form>

        </Grid>

    );
}

export default CompanyInfo;