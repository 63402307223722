import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

// mui
import { Backdrop, Box, Button, CircularProgress, Dialog, Stack, TextField, Typography } from '@mui/material';

// mui icons
import { ArrowBackIos } from '@mui/icons-material';

import moment from 'moment';

// types
import { Invoice } from '../../../types/Invoice.types';

// css
import { inputPropsStyle } from '../../../css/formStyle';

// utils
import { customDELETE, customGET, customPOSTForm, customPUT } from '../../../utils/customFetch';

// misc
import { permissions } from '../../../localStorage/permission';
import { SERVER_ERROR } from '../../../shared/errorMessage';


const BACKEND_URL = process.env.REACT_APP_BACKEND_URL


const initialData: Invoice = {
    id: null,
    contract_id: null,
    invoice_no: null,
    invoice_month: null,
    object_name: null,
    object_upload_date: null,
    object_upload_id: null,
    is_checked: null,
    create_id: null,
    create_datetime: null,
    update_id: null,
    update_datetime: null,
    deleted: null,
}


function InvoiceUpload() {
    const navigate = useNavigate();
    const { invoiceId } = useParams();
    const [data, setData] = useState<Invoice>(initialData);
    const [backdropOpen, setBackdropOpen] = useState(false);


    const saveInvoiceNo = async (event) => {
        try {
            const res = await customPUT(`${BACKEND_URL}/api/v1/invoice/${invoiceId}`, data);

            if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }
            navigate(0); // refresh page

        } catch (error) {
            console.log(error);
            return;
        }
    }

    const uploadInvoice = async (event) => {
        // check invoice_no
        if (data.invoice_no.length == 0) {
            alert("🔴 發票號碼未填入");
            return;
        }

        setBackdropOpen(true);

        console.log(event.target.files);
        const file = event.target.files[0];
        const formData = new FormData();    // multipart/form-data
        formData.append('file', file);

        try {
            const res = await customPOSTForm(`${BACKEND_URL}/api/v1/invoice/${invoiceId}/file`, formData);

            if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }

            alert("🟢 Changes saved");
            navigate(0); // refresh page

        } catch (error) {
            setBackdropOpen(false);
            console.log(error);
            return;
        }
    }

    const deleteInvoice = async (event) => {
        setBackdropOpen(true);
        try {
            const res = await customDELETE(`${BACKEND_URL}/api/v1/invoice/${invoiceId}/file`);

            if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }

            alert("🟢 Changes saved");
            navigate(0); // refresh page

        } catch (error) {
            setBackdropOpen(false);
            console.log(error);
            return;
        }
    }

    const downloadInvoice = async (event) => {
        try {
            const res = await customGET(`${BACKEND_URL}/api/v1/invoice/${invoiceId}/file`);

            // download file
            var x = document.createElement("a");
            x.href = window.URL.createObjectURL(await res.blob());
            x.download = res.headers.get('Content-Disposition').split("=")[1];
            x.click();
            x.remove();

            if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }

        } catch (error) {
            console.log(error);
            return;
        }
    }

    function invoice_month_moment(invoice_month: number): moment.Moment {
        return moment(invoice_month).local();
    }

    const init = async () => {

        // fetch invoice
        try {
            const res = await customGET(`${BACKEND_URL}/api/v1/invoice/${invoiceId}/uploadername`);
            var data = await res.json();
            console.log(data);


            if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }

            setData(data);

        } catch (error) {
            console.log(error);
            return;
        }

        // // fetch billing_name
        // try {
        //     const res = await customGET(`${BACKEND_URL}/api/v1/billing-account/${data.billing_account_id}`);
        //     const resJson = await res.json();

        //     if (res.status != 200) {
        //         alert("🔴 Server error");
        //         return;
        //     }

        //     setData({ ...data, billing_name: resJson.billing_name });

        // } catch (error) {
        //     return;
        // }
    }

    useEffect(() => {
        init();
    }, []);


    return (
        <>
            <Box
                sx={{
                    padding: "10px",
                    display: "flex",
                    justifyContent: "flex-start"
                }}
            >
                {/*  go back button */}
                <Button variant="contained" size="small" startIcon={<ArrowBackIos sx={{}} />} color="primary" disableElevation disableRipple sx={{ margin: "0px", width: "80px", borderRadius: "10px" }} onClick={() => navigate("../.")}> 返回 </Button>
            </Box>

            <Stack
                direction="column"
                sx={{
                    width: "100%",
                    background: "white",
                    borderRadius: "20px",
                    padding: "10px 30px",
                    // textAlign: "left",
                }}
            >
                {/* row 1 */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        height: "30px",
                        margin: "5px 0px"
                    }}
                >
                    <Typography
                        align="left"
                        width="120px"
                        fontSize="14px"
                    >
                        發票號碼：

                    </Typography>

                    <Typography
                        align="left"
                        fontSize="14px"
                    >
                        {data.invoice_no ? data.invoice_no : "N/A"}
                    </Typography>
                </Box>

                {/* row 2 */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        height: "30px",
                        margin: "5px 0px"
                    }}
                >
                    <Typography
                        align="left"
                        width="120px"
                        fontSize="14px"
                    >
                        發票檔案：
                    </Typography>
                    <Typography
                        align="left"
                        flexGrow="1"
                        fontSize="14px"
                    >
                        {data.object_name ? data.object_name : "N/A"}
                    </Typography>
                </Box>

                {/* row 3 */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        height: "30px",
                        margin: "5px 0px"
                    }}
                >
                    <Typography
                        align="left"
                        width="120px"
                        fontSize="14px"
                    >
                        發票月份：
                    </Typography>
                    <Typography
                        align="left"
                        flexGrow="1"
                        fontSize="14px"
                    >
                        {
                            data.invoice_month ?
                                `${new Date(data.invoice_month).getFullYear()}年${(new Date(data.invoice_month)).getMonth() + 1}月`
                                : "N/A"
                        }
                    </Typography>

                </Box>

                {/* row 4 */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        height: "30px",
                        margin: "5px 0px"
                    }}
                >
                    <Typography
                        align="left"
                        width="120px"
                        fontSize="14px"
                    >
                        訂單編號：
                    </Typography>
                    <Typography
                        align="left"
                        flexGrow="1"
                        fontSize="14px"
                    >
                        {
                            data.contract_id ? data.contract_id : "N/A"
                        }
                    </Typography>

                    {
                        permissions.includes("INV001") &&
                        data.object_name &&
                        <Button
                            onClick={downloadInvoice}
                            variant="contained"
                            size="small"
                            sx={{
                                fontSize: "12px"
                            }}
                        >
                            下載發票
                        </Button>
                    }
                </Box>

                {/* row 5 */}
                {/* <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        height: "30px",
                        margin: "5px 0px"
                    }}
                >
                    <Typography
                        align="left"
                        width="120px"
                        fontSize="14px"
                    >
                        帳號：
                    </Typography>
                    <Typography
                        align="left"
                        flexGrow="1"
                        fontSize="14px"
                    >
                        {
                            data.billing_name ? data.billing_name : "N/A"
                        }
                    </Typography>
                </Box> */}

                {/* row 6 */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        height: "30px",
                        margin: "5px 0px"
                    }}
                >
                    <Typography
                        align="left"
                        width="120px"
                        fontSize="14px"
                    >
                        上傳日期：
                    </Typography>
                    <Typography
                        align="left"
                        flexGrow="1"
                        fontSize="14px"
                    >
                        {data.object_upload_date ? new Date(data.object_upload_date).toLocaleString("zh") : "N/A"}
                    </Typography>
                </Box>

                {/* row 7 */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        height: "30px",
                        margin: "5px 0px"
                    }}
                >
                    <Typography
                        align="left"
                        width="120px"
                        fontSize="14px"
                    >
                        上傳者：
                    </Typography>
                    <Typography
                        fontSize="14px"
                    >
                        {data.object_upload_id ? data.object_upload_id : "N/A"}
                    </Typography>

                </Box>

                {/* row 8 */}
                {
                    data.object_name &&
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            height: "30px",
                            margin: "5px 0px"
                        }}
                    >
                        <Typography
                            align="left"
                            flexGrow="1"
                            fontSize="14px"
                        >
                            客戶已查看或下載發票：&nbsp;&nbsp;
                            {data.is_checked ? "是" : "否"}
                        </Typography>
                    </Box>
                }
            </Stack>

            {/* popup progress */}
            <Backdrop open={backdropOpen}>
                <CircularProgress sx={{ color: "#fff" }} />
            </Backdrop>
        </>
    );
}


export default InvoiceUpload;