// This file contains shared style of TextField (Mui)

// adjust Textfield input css
// ref: https://aguidehub.com/blog/mui-textfield-change-height-example/


export const inputPropsStyle = {
    style: {
        height: "12px",
        fontSize: "12px",
        borderRadius: "5px",
        color: "#2B3674"    // limit gray area within input box
    }
}; // add borderRadius to contrain background coloring within text box

export const inputSX = {
    "& .MuiInputBase-input.Mui-disabled": {
        background: "#E1E1E1",              //  background color when disabled 
        WebkitTextFillColor: "#2B3674"
    },
    fieldset: { borderColor: "#2380C3" },
    width: "100%"
};

export const SelectDisplayProps = {
    style: {
        paddingTop: "4px",
        paddingBottom: "3px",
        fontSize: "12px",
        color: "#2B3674"
    }
};

export const inputLabelStyle = {
    textAlign: "left",
    fontSize: "14px",
    color: "black"
};
