import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

// mui
import { Box, IconButton, Tooltip } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

// mui icons
import { EditNoteOutlined } from '@mui/icons-material';

// type
import { ContractTableRow } from '../../types/Contract.types';

// components
import TableToolbar from './TableToolbar';

// utils
import { customGET } from '../../utils/customFetch';

// misc
import { SERVER_ERROR } from '../../shared/errorMessage';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;


// type definition
type Order = 'asc' | 'desc';


// columns of table header
const headCells = [
    {
        id: "id",
        label: "訂單編號",
        width: "10%",
    },
    {
        id: "effective_start_date",
        label: "合約有效起始日",
        width: "15%",
    },
    {
        id: "effective_end_date",
        label: "合約結束日",
        width: "15%",
    },
    {
        id: "billing_type",
        label: "記帳類別",
        width: "10%",
    },
    {
        id: "status",
        label: "狀態",
        width: "10%",
    },
    {
        id: "account",
        label: "帳號",
        width: "20%",
    },
    {
        id: "create_datetime",
        label: "建立時間",
        width: "15%",
    },
];


export default function Contract() {
    const navigate = useNavigate();
    // const [order, setOrder] = useState<Order>('asc'); // sort order
    // const [orderBy, setOrderBy] = useState<keyof Data>("order_id"); // sort target
    // const [totalPage, setTotalPage] = useState(0);
    // const [page, setPage] = useState(1); // start from 1
    // const [rowsPerPage, setRowsPerPage] = useState(20);
    // const [visibleRows, setVisibleRows] = useState<Data[]>([]);
    const [rows, setRows] = useState<ContractTableRow[]>([]);
    const { id } = useParams();


    // const handleRequestSort = (
    //     event: React.MouseEvent<unknown>,
    //     property: keyof Data,
    // ) => {
    //     const isAsc = orderBy === property && order === 'asc';
    //     setOrder(isAsc ? 'desc' : 'asc'); // flip state
    //     setOrderBy(property);
    // };

    // const handleChangePage = (event: unknown, newPage: number) => {
    //     setPage(newPage);
    // };

    // // number of empty rows = rowsPerPage - actual numbers of rows
    // const emptyRows = Math.max(0, rowsPerPage - visibleRows.length);


    const init = async () => {
        const params = new URLSearchParams({
            customer_id: id
        });

        try {
            const res = await customGET(`${BACKEND_URL}/api/v1/contract/customer?${params}`);
            var contractList: Array<any> = await res.json();
            console.log("✅ fetch");

            if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }
        } catch (error) {
            console.log(error);
            return;
        }

        try {
            // set status
            const _rows = contractList.map(async (item) => {
                // fetch accounts
                const res = await customGET(`${BACKEND_URL}/api/v1/billing-account/contract?contract_id=${item.id}`);
                const accountList: Array<any> = await res.json();

                return {
                    ...item,
                    status: item.status == "suspended" ? "手動終止" : !item.isArchived ? "有效" : "過期",
                    account: accountList.map(item => item.billing_name).join(", "),  // show name
                }
            })

            setRows(await Promise.all(_rows));
        } catch (error) {
            console.log(error);
            return;
        }

    }

    const renderEditButton = (id: string) => {
        return (
            <Tooltip title={"edit"} placement="right">
                <IconButton
                    size="small"
                    color="primary"
                    sx={{ padding: 0 }}
                    onClick={() => navigate(`${id}`, {
                        state: {
                            isEdit: true
                        }
                    })}
                >
                    {<EditNoteOutlined />}
                </IconButton>
            </Tooltip>
        );
    }

    /**
     * event handler when a row is clicked
     */
    const rowOnClick = (id: string) => (event: any) => {
        navigate(`${id}`, {
            state: {
                isEdit: false
            }
        });
    }

    useEffect(() => {
        init();
    }, []);

    return (
        <div style={{ padding: "10px 0px 10px" }}>
            {/* white container (start) */}
            <TableToolbar />

            <TableContainer sx={{ height: "70%", padding: "0px", borderRadius: "0px 0px 20px 20px", background: "white" }}>
                <Table
                    stickyHeader
                    aria-labelledby="tableTitle"
                    size="small" // control row density
                >
                    {/* table header */}
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align={"left"}
                                    sx={{ fontSize: "12px" }}
                                    width={headCell.width}
                                >
                                    {headCell.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    {/* table body */}
                    <TableBody>
                        {rows.map((row, index) => {

                            return (
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={row.id}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <TableCell
                                        component="th"
                                        id={`table-row-${index}`}
                                        scope="row"
                                        align="left"
                                        width="10%"
                                        sx={{ fontSize: "12px" }}
                                        onClick={rowOnClick(row.id)}
                                    >
                                        {row.id}
                                    </TableCell>

                                    <TableCell
                                        align="left"
                                        width="15%"
                                        sx={{ fontSize: "12px" }}
                                        onClick={rowOnClick(row.id)}
                                    >
                                        {(new Date(row.effective_start_date)).toLocaleDateString('zh', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                        })}
                                    </TableCell>

                                    <TableCell
                                        align="left"
                                        width="15%"
                                        sx={{ fontSize: "12px" }}
                                        onClick={rowOnClick(row.id)}
                                    >
                                        {(new Date(row.effective_end_date)).toLocaleDateString('zh', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                        })}
                                    </TableCell>

                                    <TableCell
                                        align="left"
                                        width="10%"
                                        sx={{ fontSize: "12px" }}
                                        onClick={rowOnClick(row.id)}
                                    >
                                        {row.billing_type}
                                    </TableCell>

                                    <TableCell
                                        align="left"
                                        width="10%"
                                        sx={{ fontSize: "12px" }}
                                        onClick={rowOnClick(row.id)}
                                    >
                                        {row.status}
                                    </TableCell>

                                    <TableCell
                                        align="left"
                                        width="20%"
                                        sx={{ fontSize: "12px" }}
                                        onClick={rowOnClick(row.id)}
                                    >
                                        {row.account}
                                    </TableCell>

                                    <TableCell
                                        align="left"
                                        width="15%"
                                        sx={{ fontSize: "12px" }}
                                        onClick={rowOnClick(row.id)}
                                    >
                                        {(new Date(row.create_datetime)).toLocaleDateString('zh', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                        })}
                                        {" "}
                                        {(new Date(row.create_datetime)).toLocaleTimeString('zh', {
                                            hour12: false,
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                        })}
                                    </TableCell>

                                </TableRow>
                            );
                        })}

                    </TableBody>
                </Table>
            </TableContainer>
            {/* white container (end) */}
        </div>
    );
}
