import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

// mui
import { Button, Dialog, DialogActions, DialogTitle, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Tooltip, Typography } from "@mui/material";

// mui icons
import { Add, DeleteOutline, EditNoteOutlined } from "@mui/icons-material";

// types
import { AccountRow } from "../../types/Account.types";

// utils
import { customDELETE, customGET } from "../../utils/customFetch";

// misc
import { permissions } from "../../localStorage/permission";
import { SERVER_ERROR } from '../../shared/errorMessage';


const BACKEND_URL = process.env.REACT_APP_BACKEND_URL


// columns of table header
const headCells = [
    {
        id: "gmail",
        label: "Google 登入郵件帳號",
        width: "20%",
    },
    {
        id: "name",
        label: "使用者姓名",
        width: "20%"
    },
    {
        id: "role",
        label: "角色",
        width: "10%",
    },
    {
        id: "billing_account",
        label: "綁定的帳號",
        width: "30%",
    },
    {
        id: "status",
        label: "狀態",
        width: "10%",
    },
    {
        id: "action",
        label: "動作",
        width: "10%",
    },
];


function AccountTable() {
    const navigate = useNavigate();
    const [rows, setRows] = useState<AccountRow[]>([]);
    const { id } = useParams();

    // delete dialog
    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteId] = useState("");


    const init = async () => {
        const param = new URLSearchParams({
            customer_id: id
        });

        // fetch user
        try {
            const res = await customGET(`${BACKEND_URL}/api/v1/account/customer?${param}`);
            const resJson: Array<any> = await res.json();
            console.log("✅ fetch");

            if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }

            const accountRows = resJson.map(async (item, index) => {
                // fetch role name
                let res = await customGET(`${BACKEND_URL}/api/v1/role/customer/${item.role_id}`);
                let resJson = await res.json();
                const role_name = resJson.name;

                // bound billing accounts
                res = await customGET(`${BACKEND_URL}/api/v1/billing-account/account?account_id=${item.id}`);
                const billingAccountList: Array<any> = await res.json();

                return {
                    ...item,
                    role_name: role_name,
                    billingAccountList: billingAccountList.map(item => item.billing_name).join(", ")
                }
            })

            setRows(await Promise.all(accountRows));

        } catch (error) {
            console.log(error);
            return;
        }

    };


    const deleteAction = (id: string) => async (event) => {
        try {
            const res = await customDELETE(`${BACKEND_URL}/api/v1/account/customer/${id}`);

            if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }

            navigate(0); // refresh page

        } catch (error) {
            console.log(error);
            return;
        }
    }


    const renderButtons = (id: string, isDefault: boolean) => {

        return (
            <>
                {/* edit */}
                <Tooltip title={"edit"} placement="left">
                    <IconButton
                        size="small"
                        color="primary"
                        sx={{ padding: 0 }}
                        onClick={() => navigate(`${id}`)}
                    >
                        {<EditNoteOutlined />}
                    </IconButton>
                </Tooltip>

                {/* delete (hidden if default role) */}
                {
                    !isDefault &&
                    <Tooltip title={"delete"} placement="right">
                        {
                            permissions.includes("CUU004") &&
                            <IconButton
                                size="small"
                                color="error"
                                sx={{ marginLeft: "5px", padding: 0 }}
                                onClick={() => {
                                    setDeleteId(id);
                                    setOpen(true);
                                }}
                            >
                                {<DeleteOutline fontSize="small" />}
                            </IconButton>
                        }
                    </Tooltip>
                }
            </>
        );
    }

    /**
     * event handler when a row is clicked
     */
    const rowOnClick = (id: string) => (event: any) => {
        navigate(`${id}`);
    }

    const createRole = () => {
        navigate(`newform`);
    }

    useEffect(() => {
        init();
    }, []);


    return (
        <div style={{ padding: "10px 0px 10px", height: "70%" }}>
            {/* white container (start) */}
            {/* Toolbar with smaller height: variant="dense" */}
            <Toolbar sx={{ padding: "20px 0px 0px", borderRadius: "20px 20px 0px 0px", background: "white" }} variant="dense" >

                <Typography
                    sx={{ flex: '1 1 auto', textAlign: "left", fontWeight: "bold" }}
                    id="tableTitle"
                    component="div"
                >
                    使用者列表
                </Typography>

                {
                    permissions.includes("CUU002") &&
                    <Tooltip title="Create">
                        <Button
                            variant="contained"
                            disableElevation
                            sx={{ borderRadius: "10px" }}
                            startIcon={<Add />}
                            onClick={createRole}
                        >
                            新增使用者
                        </Button>
                    </Tooltip>
                }

            </Toolbar>


            <TableContainer sx={{ height: "100%", padding: "0px", borderRadius: "0px 0px 20px 20px", background: "white" }}>
                <Table
                    stickyHeader
                    aria-labelledby="tableTitle"
                    size="small" // control row density
                >
                    {/* table header */}
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align={"left"}
                                    sx={{ fontSize: "12px" }}
                                    width={headCell.width}
                                >
                                    {headCell.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    {/* table body */}
                    <TableBody>
                        {rows.map((row, index) => {

                            return (
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={row.id}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <TableCell
                                        component="th"
                                        id={`table-row-${index}`}
                                        scope="row"
                                        align="left"
                                        width="20%"
                                        sx={{ fontSize: "12px" }}
                                        onClick={rowOnClick(row.id)}
                                    >
                                        {row.google_login_mail}
                                    </TableCell>

                                    <TableCell
                                        component="th"
                                        id={`table-row-${index}`}
                                        scope="row"
                                        align="left"
                                        width="20%"
                                        sx={{ fontSize: "12px" }}
                                        onClick={rowOnClick(row.id)}
                                    >
                                        {row.name}
                                    </TableCell>

                                    <TableCell
                                        component="th"
                                        id={`table-row-${index}`}
                                        scope="row"
                                        align="left"
                                        width="10%"
                                        sx={{ fontSize: "12px" }}
                                        onClick={rowOnClick(row.id)}
                                    >
                                        {row.role_name}
                                    </TableCell>

                                    <TableCell
                                        component="th"
                                        id={`table-row-${index}`}
                                        scope="row"
                                        align="left"
                                        width="30%"
                                        sx={{ fontSize: "12px" }}
                                        onClick={rowOnClick(row.id)}
                                    >
                                        {row.billingAccountList}
                                    </TableCell>

                                    <TableCell
                                        align="left"
                                        width="10%"
                                        sx={{ fontSize: "12px" }}
                                        onClick={rowOnClick(row.id)}
                                    >
                                        {row.status ? "啟用" : "停用"}
                                    </TableCell>

                                    <TableCell
                                        align="left"
                                        width="10%"
                                        sx={{ fontSize: "12px" }}
                                    >
                                        {renderButtons(row.id, row.is_default)}
                                    </TableCell>

                                </TableRow>
                            );
                        })}

                    </TableBody>
                </Table>
            </TableContainer>
            {/* white container (end) */}

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogTitle>{"確定刪除？"}</DialogTitle>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}> 取消 </Button>
                    <Button onClick={deleteAction(deleteId)}
                    >
                        確定
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}


export default AccountTable;