/**
 * adapted from Companyinfo.tsx
 */
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';

// mui
import { Button, Checkbox, Grid, InputLabel, List, ListItem, ListItemButton, ListItemText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';

// mui icons
import { ArrowBackIos } from '@mui/icons-material';

// types
import { Role } from '../../../../types/Role.types';

// css
import { inputLabelStyle, inputPropsStyle, inputSX } from '../../../../css/formStyle';

// utils
import { customGET, customPOST } from '../../../../utils/customFetch';

// misc
import { customer_permission_category, internal_permission_category } from '../../../../shared/permissionCategories';
import { SERVER_ERROR } from '../../../../shared/errorMessage';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;


// helper text
const RequiredLabel = () => (<span><span style={{ color: "red" }}>*</span> <span style={{ color: "grey" }}>(必填)</span></span>);

// columns of table header
const headCells = [
    {
        id: "checkbox",
        label: "啟用",
        width: "20%",
    },
    {
        id: "id",
        label: "權限編號",
        width: "40%"
    },
    {
        id: "name",
        label: "權限名稱",
        width: "40%",
    },
];


const initialFormData: Role = {
    id: "",
    name: "",
    customer_id: "",
    is_default: false,
};

// columns that require validation
const initErrColumn = {
    name: false,
};


function CreateRoleForm() {
    const navigate = useNavigate();
    const location = useLocation(); // to get url path
    const [formData, setFormData] = useState<Role>(initialFormData);
    const [permission, setPermission] = useState([]);
    const [selectedPermission, setSelectedPermission] = useState<Array<String>>([]);
    const [activeItem, setActiveItem] = useState({
        text: "客戶查詢與基本資料",
        prefix: "CUS",
    });
    const [errColumn, setErrColumn] = useState(initErrColumn);

    const customerId = "";
    const [customerName, setCustomerName] = useState("");
    const target = location.pathname.split('/')[2];


    const cancelAction = () => {
        // redirect to the role table
        navigate(`../.`);
    }

    const saveAction = async () => {
        // DEBUG
        // console.log(formData);
        // return;

        let isError = false;
        let newErrColumn = { ...errColumn };

        // validate form, mark incorrect column
        newErrColumn.name = formData.name.length == 0; // should not be empty
        isError = newErrColumn.name;

        setErrColumn(newErrColumn);

        if (isError) {
            alert("🔴 輸入格式錯誤");
            return;
        }

        const requestBody = {
            ...formData,
            customer_id: customerId,
            permission: selectedPermission,
        };

        try {
            const res = await customPOST(`${BACKEND_URL}/api/v1/role/${target}`, requestBody);

            const resJson = await res.json();

            if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }

            alert("🟢 Created successfully");

            // redirect to the role page
            if (target == "customer")
                navigate(`../${target}/${resJson.id}`);
            else
                navigate(`../${target}/${resJson.id}`);

        } catch (error) {
            console.log(error);
            return;
        }
    }

    /**
    * set the value of a single field
    */
    const setFormField = <Key extends keyof Role>(field: Key) => (event: any) => {
        console.log("🖨️ ", event.target.value);
        const value = event.target.value;

        const newFormData = { ...formData };
        newFormData[field] = value;

        setFormData(newFormData);
    }

    /**
     * update selected list
     */
    const onCheck = (id: string) => (event) => {
        if (selectedPermission.includes(id))
            setSelectedPermission(selectedPermission.filter(x => x != id));
        else
            setSelectedPermission(selectedPermission.concat(id));
    }


    const init = async (target: string) => {

        // fetch permission list
        try {
            const res = await customGET(`${BACKEND_URL}/api/v1/permission/${target}`);
            const resJson = await res.json();

            if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }

            setPermission(resJson);

        } catch (error) {
            console.log(error);
            return;
        }

        // fetch customer name
        if (target == "customer") {
            try {
                // fetch form data
                const res = await customGET(`${BACKEND_URL}/api/v1/customer`);
                var resJson = await res.json();

                if (res.status != 200) {
                    alert(SERVER_ERROR);
                    throw new Error;
                }

                setCustomerName(resJson.name);

            } catch (error) {
                console.log(error);
                return;
            }
        }
    }


    useEffect(() => {
        init(target);
    }, []);

    return (
        // Container (base): wrap Page Title, Form
        <Grid sx={{ width: '100%', height: "70%" }}>

            {/* go back button */}
            <div style={{ textAlign: "left" }}>
                <Button variant="contained" size="small" startIcon={<ArrowBackIos />} color="primary" disableElevation disableRipple sx={{ margin: "0px", width: "80px", borderRadius: "10px" }} onClick={() => navigate("../.")}> 返回 </Button>
            </div>

            {/* white container */}
            {/* (adapted from Companyinfo.tsx) */}
            <Grid container rowSpacing={1} sx={{ padding: "20px 20px", background: "white", marginTop: "10px", marginBottom: "10px", borderRadius: "20px" }}>


                <Grid item xs={12}>
                    <InputLabel shrink sx={inputLabelStyle} >群組 </InputLabel>
                    <TextField
                        value={target == "customer" ? customerName : "管理後台"}
                        size="small"
                        fullWidth
                        inputProps={inputPropsStyle}
                        sx={inputSX}
                        disabled
                    />

                </Grid>


                <Grid item xs={12}>
                    <InputLabel shrink sx={inputLabelStyle} >角色名稱 {<RequiredLabel />}</InputLabel>
                    <TextField
                        value={formData.name}
                        onChange={setFormField("name")}
                        size="small"
                        fullWidth
                        inputProps={inputPropsStyle}
                        sx={inputSX}
                        error={errColumn.name}
                    />

                </Grid>

                <Grid item xs={12}>
                    <Typography textAlign="left" fontSize="11px" marginTop="10px"> 權限設定 </Typography>
                </Grid>

                <Grid item xs={3}>

                    {/* permission table */}
                    <List dense>
                        {
                            (target == "customer" ? customer_permission_category : internal_permission_category).map((item, index) => {
                                return (
                                    <ListItem key={item.text} disablePadding >
                                        <ListItemButton selected={activeItem.text == item.text} onClick={() => setActiveItem(item)} disableRipple>
                                            <ListItemText primary={item.text} sx={{ padding: "0px 0px 0px 10px" }} />
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })
                        }
                    </List>
                </Grid>

                <Grid item xs={9}>

                    <TableContainer sx={{}}>
                        <Table
                            stickyHeader
                            aria-labelledby="tableTitle"
                            size="small" // control row density
                        >
                            {/* table header */}
                            <TableHead>
                                <TableRow>
                                    {headCells.map((headCell) => (
                                        <TableCell
                                            key={headCell.id}
                                            align={"left"}
                                            sx={{ fontSize: "12px" }}
                                            width={headCell.width}
                                        >
                                            {headCell.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            {/* table body */}
                            <TableBody>
                                {
                                    permission.filter(item => item.id.substring(0, 3) == activeItem.prefix).map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={row.id}
                                                selected={selectedPermission.includes(row.id)}
                                            >
                                                {/* checkbox */}
                                                <TableCell padding="checkbox" width="20%">
                                                    <Checkbox
                                                        color="primary"
                                                        checked={selectedPermission.includes(row.id)}
                                                        onChange={onCheck(row.id)}
                                                        size="small"
                                                    />
                                                </TableCell>

                                                <TableCell
                                                    component="th"
                                                    id={`table-row-${index}`}
                                                    scope="row"
                                                    align="left"
                                                    width="40%"
                                                    sx={{ fontSize: "12px" }}
                                                >
                                                    {row.id}
                                                </TableCell>

                                                <TableCell
                                                    align="left"
                                                    width="40%"
                                                    sx={{ fontSize: "12px" }}
                                                >
                                                    {row.name}
                                                </TableCell>

                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Grid>

                <Grid xs={12} sx={{ width: '100%', display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                    <Button variant="contained" color="inherit" size="small" sx={{ margin: "auto 10px" }} onClick={cancelAction}> 取消 </Button>
                    <Button variant="contained" color="primary" size="small" sx={{ margin: "auto 10px" }} onClick={saveAction}> 儲存 </Button>
                </Grid>
            </Grid>
        </Grid>


    );
}


export default CreateRoleForm;