import React from 'react';
import logo from './logo.svg';

import { Box } from '@mui/material';

function Stats() {

    return (
        <Box component="main" sx={{ flexGrow: 1, padding: "30px" }}>
            Stats Page
        </Box>
    );
}

export default Stats;
