import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router";

// mui
import { BottomNavigation, BottomNavigationAction, Box, Button, Grid, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";

// mui icons
import { CorporateFare, Description, Group, ManageAccounts, ReceiptLong } from "@mui/icons-material";

// utils
import { customGET } from "../../utils/customFetch";

// misc
import { SERVER_ERROR } from "../../shared/errorMessage";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL


const NavigationArray = [
    { path: "info", label: "客戶資訊", Icon: <CorporateFare /> },
    { path: "contract", label: "合約", Icon: <Description /> },
    { path: "billing_account", label: "帳號", Icon: <ManageAccounts /> },
    { path: "invoice", label: "發票", Icon: <ReceiptLong /> },
    { path: "account", label: "使用者", Icon: <Group /> },
];

function IndividualCompanyLayout() {
    const location = useLocation(); // to get url path
    const navigate = useNavigate();
    const [activeItem, setActiveItem] = useState("");
    const [companyName, setCompanyName] = useState("");
    const { id } = useParams();

    const ItemOnClick = (itemName: string) => {
        setActiveItem(itemName);
        navigate(`${itemName}`);  // relative path
    }

    /** initialize */
    const init = async () => {
        try {
            // fetch form data
            const res = await customGET(`${BACKEND_URL}/api/v1/customer`);
            const data = await res.json();
            console.log(data);

            if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }

            setCompanyName(data.name);

            console.log("✅ ", data);
        } catch (error) {
            console.log(error);
            return;
        }

    }

    useEffect(() => {
        init();
        setActiveItem(location.pathname.split('/')[3]);
    });

    return (
        // Container (base): wrap Nav bar, Main Body
        <Grid sx={{ width: '100%', height: "100vh", padding: "30px", overflow: "auto" }}>

            {/* Title */}
            <Grid xs={12} sx={{ width: '100%', display: "flex", justifyContent: "flex-start", padding: "10px 0px" }}>
                <Typography
                    sx={{ flex: '1 1 auto', textAlign: "left", fontWeight: "bold" }}
                    variant="h5"
                    id="tableTitle"
                    component="div"
                >
                    {companyName}
                </Typography>
            </Grid>

            <Grid container>
                {/* navigation bar */}
                {
                    NavigationArray.map((item) => (
                        <ListItem key={item.path} disablePadding dense sx={{ width: "130px", margin: "5px" }} >
                            <ListItemButton selected={activeItem == item.path} onClick={() => ItemOnClick(item.path)} disableRipple sx={{ borderRadius: "10px" }}>
                                <ListItemIcon sx={{ minWidth: "0px" }}>
                                    {item.Icon}
                                </ListItemIcon>
                                <ListItemText primary={item.label} sx={{ padding: "0px 0px 0px 10px" }} />
                            </ListItemButton>
                        </ListItem>
                    ))
                }
            </Grid>

            {/* render Main Body */}
            <Outlet />
        </Grid>
    );
}


export default IndividualCompanyLayout;