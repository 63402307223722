import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

// mui
import { Autocomplete, Button, Dialog, DialogActions, DialogTitle, IconButton, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Tooltip, Typography } from "@mui/material";

// mui icons
import { Add, DeleteOutline, EditNoteOutlined } from "@mui/icons-material";

// types
import { RoleRow } from "../../../types/Role.types";

// utils
import { customDELETE, customGET } from "../../../utils/customFetch";

// misc
import { permissions } from "../../../localStorage/permission";
import { SERVER_ERROR } from "../../../shared/errorMessage";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL


// columns of table header
const headCells = [
    {
        id: "name",
        label: "角色名稱",
        width: "20%",
    },
    {
        id: "create_datetime",
        label: "建立時間",
        width: "20%"
    },
    {
        id: "update_datetime",
        label: "修改時間",
        width: "20%",
    },
    {
        id: "default",
        label: "系統預設",
        width: "20%",
    },
    {
        id: "action",
        label: "動作",
        width: "20%",
    },
];


function RoleTable() {
    const navigate = useNavigate();
    const [rows, setRows] = useState<RoleRow[]>([]);

    // delete dialog
    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteId] = useState("");


    /* fetch role, customer */
    const search = async () => {

        try {
            const res = await customGET(`${BACKEND_URL}/api/v1/role/customer`);
            var resJson: Array<any> = await res.json();
            console.log("✅ fetch");

            if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }


        } catch (error) {
            console.log(error);
            return;
        }

        setRows(resJson);

    };

    const deleteAction = (id: string) => async (event) => {
        try {
            const res = await customDELETE(`${BACKEND_URL}/api/v1/role/customer/${id}`);

            if (res.status == 400) {
                alert("🔴 角色已被綁定");
                return;
            }
            else if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }

            // fetch new data
            search();
            setOpen(false);

        } catch (error) {
            console.log(error);
            return;
        }
    }

    const renderButtons = (id: string, isDefault: boolean) => {

        return (
            <>
                {/* edit */}
                <Tooltip title={"edit"} placement="left">
                    <IconButton
                        size="small"
                        color="primary"
                        sx={{ padding: 0 }}
                        onClick={() => navigate(`customer/${id}`)}
                    >
                        {<EditNoteOutlined />}
                    </IconButton>
                </Tooltip>

                {/* delete (hidden if default role) */}
                {
                    permissions.includes("CUR004") &&
                    !isDefault &&
                    <Tooltip title={"delete"} placement="right">
                        <IconButton
                            size="small"
                            color="error"
                            sx={{ marginLeft: "5px", padding: 0 }}
                            onClick={() => {
                                setDeleteId(id);
                                setOpen(true);
                            }}
                        >
                            {<DeleteOutline fontSize="small" />}
                        </IconButton>
                    </Tooltip>
                }
            </>
        );
    }

    /**
     * event handler when a row is clicked
     */
    const rowOnClick = (id: string) => (event: any) => {
        navigate(`customer/${id}`)
    }

    const createRole = () => {
        navigate(`customer/newform`);
    }

    useEffect(() => {
        search();
    }, []);


    return (
        <div style={{ padding: "10px 0px 10px", height: "70%" }}>
            {/* white container (start) */}
            {/* Toolbar with smaller height: variant="dense" */}
            <Toolbar sx={{ padding: "20px 0px 0px", borderRadius: "20px 20px 0px 0px", background: "white" }} variant="dense" >
                <Typography
                    sx={{ fontWeight: "bold", fontSize: "20px", margin: "0px 10px 0px 0px" }}
                    id="tableTitle"
                    component="div"
                >
                    角色列表
                </Typography>

                <div style={{ flex: "1 1" }}></div>

                {/* if target == "customer", enable create button only when customer id is provided */}
                {
                    permissions.includes("CUR002") &&
                    <Tooltip title="Create">
                        <Button
                            variant="contained"
                            disableElevation
                            sx={{ borderRadius: "10px" }}
                            startIcon={<Add />}
                            onClick={createRole}
                        >
                            新增角色
                        </Button>
                    </Tooltip>
                }
            </Toolbar>

            <TableContainer sx={{ height: "100%", padding: "0px", borderRadius: "0px 0px 20px 20px", background: "white" }}>
                <Table
                    stickyHeader
                    aria-labelledby="tableTitle"
                    size="small" // control row density
                >
                    {/* table header */}
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align={"left"}
                                    sx={{ fontSize: "12px" }}
                                    width={headCell.width}
                                >
                                    {headCell.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    {/* table body */}
                    <TableBody>
                        {rows.map((row, index) => {

                            return (
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={row.id}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <TableCell
                                        component="th"
                                        id={`table-row-${index}`}
                                        scope="row"
                                        align="left"
                                        width="20%"
                                        sx={{ fontSize: "12px" }}
                                        onClick={rowOnClick(row.id)}
                                    >
                                        {row.name}
                                    </TableCell>

                                    <TableCell
                                        align="left"
                                        width="20%"
                                        sx={{ fontSize: "12px" }}
                                        onClick={rowOnClick(row.id)}
                                    >
                                        {row.create_datetime == null ? "-" : (new Date(row.create_datetime)).toLocaleDateString('zh', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                            hour12: false,
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                        })}
                                    </TableCell>

                                    <TableCell
                                        align="left"
                                        width="20%"
                                        sx={{ fontSize: "12px" }}
                                        onClick={rowOnClick(row.id)}
                                    >
                                        {row.update_datetime == null ? "-" : (new Date(row.update_datetime)).toLocaleDateString('zh', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                            hour12: false,
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                        })}
                                    </TableCell>

                                    <TableCell
                                        align="left"
                                        width="20%"
                                        sx={{ fontSize: "12px" }}
                                        onClick={rowOnClick(row.id)}
                                    >
                                        {row.is_default ? "是" : "否"}
                                    </TableCell>

                                    <TableCell
                                        align="left"
                                        width="10%"
                                        sx={{ fontSize: "12px" }}
                                    >
                                        {renderButtons(row.id, row.is_default)}
                                    </TableCell>

                                </TableRow>
                            );
                        })}

                    </TableBody>
                </Table>
            </TableContainer>
            {/* white container (end) */}
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogTitle>{"確定刪除？"}</DialogTitle>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}> 取消 </Button>
                    <Button onClick={deleteAction(deleteId)}
                    >
                        確定
                    </Button>
                </DialogActions>
            </Dialog>

        </div >
    );
}


export default RoleTable;