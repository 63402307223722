/**
 * adapted from Companyinfo.tsx
 */
import { useLocation, useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';

// mui
import { Button, TextField, FormHelperText, InputLabel, MenuItem, Select, Typography, Toolbar, TableContainer, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Grid } from '@mui/material';

// mui icon
import { ArrowBackIos, Edit } from '@mui/icons-material';

// types
import { BillingAccount, ContractTableRow } from '../../../types/Contract.types';

// css
import { inputPropsStyle, inputSX, SelectDisplayProps, inputLabelStyle } from '../../../css/formStyle';

// utils
import { customGET, customPUT } from '../../../utils/customFetch';

// misc
import { enabled } from '../../../shared/formSelectOptions';
import { permissions } from '../../../localStorage/permission';
import { SERVER_ERROR, NOT_FOUND } from '../../../shared/errorMessage';


const BACKEND_URL = process.env.REACT_APP_BACKEND_URL


// helper text
const RequiredLabel = () => (<span><span style={{ color: "red" }}>*</span> <span style={{ color: "grey" }}>(必填)</span></span>);


const initialFormData: BillingAccount = {
    id: "",
    billing_name: "",
    customer_id: "",
    status: "",
    create_id: "",
    create_datetime: 0,
    update_id: "",
    update_datetime: 0,
    contract_id: null,
    customer_account_id: null,
}

// columns that require validation
const initErrColumn = {
    id: false,
    billing_name: false,
}

// columns of table header
const headCells = [
    {
        id: "id",
        label: "訂單編號",
        width: "10%",
    },
    {
        id: "effective_start_date",
        label: "合約有效起始日",
        width: "15%",
    },
    {
        id: "effective_end_date",
        label: "合約結束日",
        width: "15%",
    },
    {
        id: "status",
        label: "狀態",
        width: "10%",
    },
    {
        id: "account",
        label: "帳號",
        width: "30%",
    },
    {
        id: "create_datetime",
        label: "建立時間",
        width: "15%",
    },
];


/**
 * The default post-action navigation will be overridden by closeCurrentPopup if closeCurrentPopup is not null
 */
function BillingAccountInfo() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [isEdit, setIsEdit] = useState(permissions.includes("ACC002") && state ? state.isEdit : false);
    const [formData, setFormData] = useState<BillingAccount>(initialFormData); // render current form state
    const [oldFormData, setOldFormData] = useState<BillingAccount>(initialFormData); // store original form state
    const [errColumn, setErrColumn] = useState(initErrColumn);
    const [rows, setRows] = useState<ContractTableRow[]>([]);
    const { id, b_id } = useParams();


    const cancelAction = () => {
        // restore original data
        setFormData(oldFormData);
        setIsEdit(false);
        navigate(".", { replace: true }); // clear location.state
    }

    const saveAction = async () => {
        // DEBUG
        // console.log(formData);
        // return;

        let isError = false;
        let newErrColumn = { ...errColumn };

        // validate form, mark incorrect column
        newErrColumn.id = formData.id.length != 20; // should has correct length
        newErrColumn.billing_name = formData.billing_name.length == 0; // should not be empty
        isError = newErrColumn.id || newErrColumn.billing_name;

        setErrColumn(newErrColumn);

        if (isError) {
            alert("🔴 輸入格式錯誤");
            return;
        }

        const requestBody = {
            ...formData,
            status: formData.status == "啟用",  // convert back to boolean
        };

        try {
            const res = await customPUT(`${BACKEND_URL}/api/v1/billing-account/${b_id}`, requestBody);

            if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }

        } catch (error) {
            console.log(error);
            return;
        }

        alert("🟢 Changes saved...");
        navigate(".", { replace: true }); // clear location.state
        navigate(0); // refresh page
    }

    /**
    * set the value of a single field
    */
    const setFormField = <Key extends keyof BillingAccount>(field: Key) => (event: any) => {
        console.log("🖨️ ", event.target.value);
        const value = event.target.value;

        const newFormData = { ...formData };
        newFormData[field] = value;

        setFormData(newFormData);
    }

    /** initialize */
    const init_formData = async () => {
        try {
            // fetch form data
            const res = await customGET(`${BACKEND_URL}/api/v1/billing-account/${b_id}`);
            var data = await res.json();
            console.log(data);

            if (res.status == 404) {
                alert(NOT_FOUND);
                throw new Error;
            } else if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }

        } catch (error) {
            console.log(error);
            return;
        }

        data.status = data.status ? "啟用" : "停用";  // convert boolean to string

        // set both current and old FormData
        setFormData(data);
        setOldFormData(data);

        if (data.contract_id)
            init_contract(data.contract_id);
    }


    /** initialize contract table*/
    const init_contract = async (contract_id: string) => {

        // fetch contract
        try {
            const res = await customGET(`${BACKEND_URL}/api/v1/contract/${contract_id}`);
            var contract = await res.json();
            console.log(contract);

            if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }
        } catch (error) {
            console.log(error);
            return;
        }

        // fetch accounts
        try {
            const res = await customGET(`${BACKEND_URL}/api/v1/billing-account/contract?contract_id=${contract_id}`);
            var accountList: Array<any> = await res.json();

            if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }
        } catch (error) {
            console.log(error);
            return;
        }

        setRows([{
            ...contract,
            status: contract.status == "suspended" ? "手動終止" : Date.now() < Date.parse(contract.effective_end_date) ? "有效" : "過期",
            account: accountList.map(data => data.billing_name).join(", "),  // show name
        }]);
    }


    /**
     * event handler when a row is clicked
     */
    const rowOnClick = (id: string) => (event: any) => {
        navigate(`../../contract/${id}`, {
            state: {
                isEdit: false
            }
        });
    }

    useEffect(() => {
        init_formData();
    }, []);

    return (
        // Container (base): wrap Page Title, Form
        <Grid sx={{ width: '100%', height: "100%", padding: "10px 0px" }}>

            <Grid xs={12} sx={{ display: "flex", justifyContent: "flex-start", padding: "0px 10px" }}>
                {/* go back button */}
                {
                    <Button variant="contained" size="small" startIcon={<ArrowBackIos />} color="primary" disableElevation disableRipple sx={{ margin: "0px", width: "80px", borderRadius: "10px" }} onClick={() => navigate("../.")}> 返回 </Button>
                }
            </Grid>

            {/* white container */}
            {/* (adapted from Companyinfo.tsx) */}
            <Grid xs={12} sx={{ padding: "20px 20px", background: "white", marginTop: "10px", marginBottom: "10px", borderRadius: "20px" }}>

                <form >
                    <Grid container columnSpacing={5} rowSpacing={1} sx={{ height: "100%", textAlign: "left" }}>
                        <Grid container item xs={12}>
                            <Typography
                                sx={{ display: "flex", alignItems: "center", fontWeight: "bold" }}
                                id="tableTitle"
                                component="div"
                            >
                                帳號資料
                            </Typography>

                            {/* show edit button when not in edit mode */}
                            {
                                permissions.includes("ACC002") && !isEdit &&
                                <Button
                                    variant="contained"
                                    size="small"
                                    disableElevation
                                    startIcon={<Edit />}
                                    color="primary"
                                    sx={{ display: "flex", margin: "0px 15px 0px" }}
                                    onClick={() => setIsEdit(true)}
                                >
                                    編輯
                                </Button>
                            }
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel shrink sx={inputLabelStyle} >專案名稱 {isEdit && <RequiredLabel />}</InputLabel>
                            <TextField
                                value={formData.billing_name}
                                onChange={setFormField("billing_name")}
                                size="small"
                                fullWidth
                                inputProps={inputPropsStyle}
                                sx={inputSX}
                                error={errColumn.billing_name}
                                disabled={!isEdit}
                            />

                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel shrink sx={inputLabelStyle} >專案ID {isEdit && <RequiredLabel />}</InputLabel>
                            <TextField
                                value={formData.id}
                                onChange={setFormField("id")}
                                size="small"
                                fullWidth
                                inputProps={inputPropsStyle}
                                sx={inputSX}
                                error={errColumn.id}
                                disabled    // prevent from modifying id
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel shrink sx={inputLabelStyle}>狀態</InputLabel>
                            <Select
                                value={formData.status}
                                onChange={setFormField("status")}
                                size="small"
                                fullWidth
                                SelectDisplayProps={SelectDisplayProps}
                                sx={inputSX}
                                disabled={!isEdit}
                            >

                                {enabled.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                </form>

            </Grid>

            {/* contract */}
            {/* white container (start) */}
            <Toolbar sx={{ padding: "20px 0px 0px", borderRadius: "20px 20px 0px 0px", background: "white" }} variant="dense" >
                <Typography
                    sx={{ flex: '1 1 auto', textAlign: "left", fontWeight: "bold" }}
                    id="tableTitle"
                    component="div"
                >
                    合約項目
                </Typography>
            </Toolbar>

            <TableContainer sx={{ padding: "0px", borderRadius: "0px 0px 20px 20px", background: "white" }}>
                <Table
                    stickyHeader
                    aria-labelledby="tableTitle"
                    size="small" // control row density
                >
                    {/* table header */}
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align={"left"}
                                    sx={{ fontSize: "12px" }}
                                    width={headCell.width}
                                >
                                    {headCell.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    {/* table body */}
                    <TableBody>
                        {rows.map((row, index) => {

                            return (
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={row.id}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <TableCell
                                        component="th"
                                        id={`table-row-${index}`}
                                        scope="row"
                                        align="left"
                                        width="10%"
                                        sx={{ fontSize: "12px" }}
                                        onClick={rowOnClick(row.id)}
                                    >
                                        {row.id}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        width="15%"
                                        sx={{ fontSize: "12px" }}
                                        onClick={rowOnClick(row.id)}
                                    >
                                        {(new Date(row.effective_start_date)).toLocaleDateString('zh', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                        })}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        width="15%"
                                        sx={{ fontSize: "12px" }}
                                        onClick={rowOnClick(row.id)}
                                    >
                                        {(new Date(row.effective_end_date)).toLocaleDateString('zh', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                        })}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        width="10%"
                                        sx={{ fontSize: "12px" }}
                                        onClick={rowOnClick(row.id)}
                                    >
                                        {row.status}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        width="30%"
                                        sx={{ fontSize: "12px" }}
                                        onClick={rowOnClick(row.id)}
                                    >
                                        {row.account}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        width="15%"
                                        sx={{ fontSize: "12px" }}
                                        onClick={rowOnClick(row.id)}
                                    >
                                        {(new Date(row.create_datetime)).toLocaleDateString('zh', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                        })}
                                        {" "}
                                        {(new Date(row.create_datetime)).toLocaleTimeString('zh', {
                                            hour12: false,
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                        })}
                                    </TableCell>
                                </TableRow>
                            );
                        })}

                    </TableBody>
                </Table>
            </TableContainer>
            {/* white container (end) */}

        </Grid>

    );
}


export default BillingAccountInfo;