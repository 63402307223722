export const customer_permission_category = [
    {
        text: "客戶查詢與基本資料",
        prefix: "CUS",
    },
    {
        text: "發票維護",
        prefix: "INV",
    },
    {
        text: "角色與權限",
        prefix: "CUR",
    },
    {
        text: "客戶使用者維護",
        prefix: "CUU",
    },
    {
        text: "異動紀錄",
        prefix: "LOG",
    },
];

export const internal_permission_category = [
    {
        text: "客戶查詢與基本資料",
        prefix: "CUS",
    },
    {
        text: "客戶合約維護",
        prefix: "CON",
    },
    {
        text: "客戶帳號維護",
        prefix: "ACC",
    },
    {
        text: "發票維護",
        prefix: "INV",
    },
    {
        text: "後台角色與權限管理",
        prefix: "BER",
    },
    {
        text: "客戶角色與權限管理",
        prefix: "CUR",
    },
    {
        text: "後台使用者維護",
        prefix: "BEU",
    },
    {
        text: "客戶使用者維護",
        prefix: "CUU",
    },
    {
        text: "合約內容設定",
        prefix: "SET",
    },
    {
        text: "異動紀錄",
        prefix: "LOG",
    },
];