/**
 * adapted from CreateContractForm.tsx
 */
import { useLocation, useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';

// mui
import { Box, Button, FormHelperText, InputLabel, MenuItem, PaletteColorOptions, Paper, Select, ThemeProvider, Typography, createTheme } from '@mui/material';
import { FormControl, TextField } from '@mui/material';
import { Grid } from '@mui/material';

// mui Date
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import 'dayjs/locale/zh-cn';    // calendar select format
import dayjs, { Dayjs } from 'dayjs';

// mui icons
import { ArrowBackIos, Edit } from '@mui/icons-material';

// types
import { BillingAccount, ContractForm } from '../../../types/Contract.types';

// css
import { inputPropsStyle, inputSX, SelectDisplayProps, inputLabelStyle } from '../../../css/formStyle';

// components
import BindBillingAccountTable from '../../BillingAccount/BindBillingAccount/BindBillingAccountTable';

// utils
import { customGET, customPUT } from '../../../utils/customFetch';

// misc
import { billing_type, contract_auto_renewal, contract_status, currencies, plan, support_plan_min_charge, support_plan_rate, taxFlag } from '../../../shared/formSelectOptions';
import { permissions } from '../../../localStorage/permission';
import { NOT_FOUND, SERVER_ERROR } from '../../../shared/errorMessage';


const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;


// helper text
const RequiredLabel = () => (<span><span style={{ color: "red" }}>*</span> <span style={{ color: "grey" }}>(必填)</span></span>);


const initialFormData: ContractForm = {
    id: "",
    customer_id: "",
    currency: "TWD",
    effective_start_date: null,
    effective_end_date: null,
    billing_type: "",
    credit_rate: "",
    support_plan: "無",
    support_plan_rate: "",
    support_plan_min_charge: "",
    support_plan_currency: "",
    tax_flag: "VAT(5%)",
    note: "",
    create_id: "",
    create_datetime: 0,
    update_id: "",
    update_datetime: 0,
    status: "automatic",
    auto_renewal: true,
}

// columns that require validation
const initErrColumn = {
    id: false,
    effective_start_date: false,
    effective_end_date: false,
}

function ContractInfo() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [isEdit, setIsEdit] = useState(permissions.includes("CON002") && state ? state.isEdit : false);   // whether form can be edit
    const [invalid, setInvalid] = useState(false);  // whether the contract is invalid
    const [formData, setFormData] = useState<ContractForm>(initialFormData); // render current form state
    const [oldFormData, setOldFormData] = useState<ContractForm>(initialFormData); // store original form state
    const [billingAccountList, setBillingAccountList] = useState<BillingAccount[]>([]); // list of selected billing account
    const [oldBillingAccountList, setOldBillingAccountList] = useState<BillingAccount[]>([]); // list of selected billing account
    const [linkedAccountList, setLinkedAccountList] = useState<BillingAccount[]>([]); // list of linked billing account
    const [errColumn, setErrColumn] = useState<any>(initErrColumn);
    const { id, contractId } = useParams();



    const cancelAction = () => {
        // restore original data
        setFormData(oldFormData);
        setBillingAccountList(oldBillingAccountList);
        setIsEdit(false);
        navigate(".", { replace: true }); // clear location.state
    }

    const saveAction = async () => {
        // DEBUG
        // console.log(formData);
        // return;

        let isError = false;
        let newErrColumn = { ...errColumn };

        // validate form, mark incorrect column
        newErrColumn.id = formData.id.length != 10; // should has correct length
        newErrColumn.effective_start_date = formData.effective_start_date == null || !formData.effective_start_date.isValid()  // not null and valid date
        newErrColumn.effective_end_date = formData.effective_end_date == null || !formData.effective_end_date.isValid()   // not null and valid date

        for (const key in errColumn)
            isError = isError || newErrColumn[key];


        setErrColumn(newErrColumn);

        if (isError) {
            alert("🔴 輸入格式錯誤");
            return;
        }

        const requestBody = {
            contract: {
                ...formData,
                customer_id: id,
                status: formData.status == "手動終止" ? "suspended" : "automatic",
                effective_start_date: (formData.effective_start_date as Dayjs).toDate(),
                effective_end_date: (formData.effective_end_date as Dayjs).toDate(),
            },
            billingAccountList: billingAccountList
        };

        try {
            const res = await customPUT(`${BACKEND_URL}/api/v1/contract/${contractId}`, requestBody);

            if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }

            alert("🟢 Changes saved!");
            navigate(".", { replace: true }); // clear location.state
            navigate(0); // refresh page
        } catch (error) {
            console.log(error);
            return;
        }
    }

    /**
    * set the value of a single field
    */
    const setFormField = <Key extends keyof ContractForm>(field: Key) => (event: any) => {
        console.log("🖨️ ", event.target.value);
        const value = event.target.value;

        const newFormData = { ...formData };
        newFormData[field] = value;

        // if plan is none, reset related column to empty
        if (field == "support_plan") {
            if (value == "無") {
                newFormData.support_plan_rate = "";
                newFormData.support_plan_min_charge = "";
                newFormData.support_plan_currency = "";
            } else {
                newFormData.support_plan_rate = support_plan_rate[value];
                newFormData.support_plan_min_charge = support_plan_min_charge[value];
                newFormData.support_plan_currency = "TWD";
            }
        }

        setFormData(newFormData);
    }

    const setDate = <Key extends keyof ContractForm>(field: Key) => (value: any) => {
        console.log("🖨️ ", value);

        const newFormData = { ...formData };
        newFormData[field] = value;

        setFormData(newFormData);
    }

    /** initialize */
    const init_formData = async () => {
        try {
            // fetch form data
            const res = await customGET(`${BACKEND_URL}/api/v1/contract/${contractId}/formData`);
            var data = await res.json();
            console.log(data);

            if (res.status == 404) {
                alert(NOT_FOUND);
                throw new Error;
            } else if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }

        } catch (error) {
            console.log(error);
            return;
        }

        // convert timestamp to dayjs
        data.contract.effective_start_date = dayjs(data.contract.effective_start_date);
        data.contract.effective_end_date = dayjs(data.contract.effective_end_date);

        // convert boolean to string
        data.contract.auto_renewal = data.contract.auto_renewal ? "是" : "否";


        // handle contract status
        if (data.contract.status == "suspended") {
            setIsEdit(false);
            setInvalid(true);
            data.contract.status = "手動終止";
        } else if (data.contract.isArchived) {
            setIsEdit(false);
            setInvalid(true);
            data.contract.status = "過期";
        } else {
            data.contract.status = "有效";
        }

        // set both current and old FormData
        setFormData(data.contract);
        setOldFormData(data.contract);

        setLinkedAccountList(data.billingAccountList);
        setBillingAccountList(data.billingAccountList);
        setOldBillingAccountList(data.billingAccountList);

    }

    useEffect(() => {
        init_formData();
    }, []);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"zh-cn"}>

            <Grid xs={12} sx={{ display: "flex", justifyContent: "flex-start", padding: "10px" }}>
                {/* go back button */}
                {
                    <Button variant="contained" size="small" startIcon={<ArrowBackIos sx={{}} />} color="primary" disableElevation disableRipple sx={{ margin: "0px", width: "80px", borderRadius: "10px" }} onClick={() => navigate("../.")}> 返回 </Button>
                }
            </Grid>

            <Grid xs={12} sx={{ padding: "20px 20px", background: "white", marginTop: "10px", marginBottom: "10px", borderRadius: "20px" }}>

                {/* Title */}
                <Grid xs={12} sx={{ display: "flex", justifyContent: "flex-start", padding: "0px 0px 10px" }}>
                    {/* show edit button when not in edit mode */}
                    {
                        permissions.includes("CON002") && !isEdit &&
                        <Button variant="contained" size="small" startIcon={<Edit />} color="primary" sx={{ margin: "0px" }} onClick={() => setIsEdit(true)} disabled={invalid}> 編輯 </Button>
                    }

                </Grid>
                <form >
                    <Grid container columnSpacing={5} rowSpacing={1} sx={{ height: "100%", textAlign: "left" }}>
                        <Grid container item xs={12}>
                            <Grid item>
                                <Typography
                                    sx={{ flex: '1 1 auto', textAlign: "left", fontWeight: "bold" }}
                                    id="tableTitle"
                                    component="div"
                                >
                                    合約資訊
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item xs={6}>
                            <InputLabel shrink sx={inputLabelStyle}>訂單編號 {<RequiredLabel />}</InputLabel>
                            <TextField
                                value={formData.id}
                                onChange={setFormField("id")}
                                size="small"
                                fullWidth
                                inputProps={inputPropsStyle}
                                sx={inputSX}
                                error={errColumn.order_id}
                                disabled={true}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <InputLabel shrink sx={inputLabelStyle}>出帳幣別</InputLabel>
                            <Select
                                value={formData.currency}
                                onChange={setFormField("currency")}
                                size="small"
                                fullWidth
                                SelectDisplayProps={SelectDisplayProps}
                                sx={inputSX}
                                disabled={!isEdit}
                            >
                                {currencies.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>

                        <Grid item xs={6}>
                            <InputLabel shrink sx={inputLabelStyle}>合約有效起始日 {<RequiredLabel />}</InputLabel>

                            <DatePicker
                                value={formData.effective_start_date}
                                onChange={setDate("effective_start_date")}
                                sx={{ width: "100%" }}
                                disabled={!isEdit}
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        inputProps: inputPropsStyle,
                                        sx: inputSX,
                                        error: errColumn.effective_start_date
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <InputLabel shrink sx={inputLabelStyle}>合約結束日 {<RequiredLabel />}</InputLabel>

                            <DatePicker
                                value={formData.effective_end_date}
                                onChange={setDate("effective_end_date")}
                                sx={{ width: "100%" }}
                                disabled={!isEdit}
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        inputProps: inputPropsStyle,
                                        sx: inputSX,
                                        error: errColumn.effective_end_date
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel shrink sx={inputLabelStyle}>合約狀態</InputLabel>
                            <Select
                                value={formData.status}
                                onChange={setFormField("status")}
                                size="small"
                                fullWidth
                                SelectDisplayProps={SelectDisplayProps}
                                sx={inputSX}
                                disabled={!isEdit}
                            >

                                {(invalid ? contract_status.concat(["過期"]) : contract_status).map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel shrink sx={inputLabelStyle} >自動續約</InputLabel>
                            <Select
                                value={formData.auto_renewal}
                                onChange={setFormField("auto_renewal")}
                                size="small"
                                fullWidth
                                SelectDisplayProps={SelectDisplayProps}
                                sx={inputSX}
                                disabled={!isEdit}
                            >

                                {contract_auto_renewal.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel shrink sx={inputLabelStyle} >出帳類別</InputLabel>
                            <Select
                                value={formData.billing_type}
                                onChange={setFormField("billing_type")}
                                size="small"
                                fullWidth
                                SelectDisplayProps={SelectDisplayProps}
                                sx={inputSX}
                                disabled={!isEdit}
                            >

                                {billing_type.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>

                        {/* DO NOT write to DB before contract is created */}
                        <Grid item xs={12}>
                            <BindBillingAccountTable billingAccountList={billingAccountList} setBillingAccountList={setBillingAccountList} linkedAccountList={linkedAccountList} disabled={!isEdit} />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography
                                sx={{ flex: '1 1 auto', textAlign: "left", fontWeight: "bold", padding: "30px 0px 0px" }}
                                id="tableTitle"
                                component="div"
                            >
                                出帳折扣
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel shrink sx={inputLabelStyle}>折扣 %</InputLabel>
                            <TextField
                                value={formData.credit_rate}
                                onChange={setFormField("credit_rate")}
                                size="small"
                                fullWidth
                                inputProps={inputPropsStyle}
                                sx={inputSX}
                                disabled={!isEdit}
                            />
                            <FormHelperText sx={{ fontStyle: "italic" }}>如果合約費用沒有折扣，請留空或輸入 0.0。 如果折抵額為原價之 10%，意為 9 折，則填入 90。</FormHelperText>
                        </Grid>


                        <Grid item xs={12}>
                            <Typography
                                sx={{ flex: '1 1 auto', textAlign: "left", fontWeight: "bold", padding: "20px 0px 0px" }}
                                id="tableTitle"
                                component="div"
                            >
                                支援方案
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel shrink sx={inputLabelStyle}>方案</InputLabel>
                            <Select
                                value={formData.support_plan}
                                onChange={setFormField("support_plan")}
                                size="small"
                                fullWidth
                                SelectDisplayProps={SelectDisplayProps}
                                sx={inputSX}
                                disabled={!isEdit}
                            >

                                {plan.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>

                        <Grid item xs={4}>
                            <InputLabel shrink sx={inputLabelStyle}>服務費率 %</InputLabel>
                            <TextField
                                value={formData.support_plan_rate}
                                onChange={setFormField("support_plan_rate")}
                                size="small"
                                fullWidth
                                inputProps={inputPropsStyle}
                                sx={inputSX}
                                disabled={!isEdit || formData.support_plan == "無"}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <InputLabel shrink sx={inputLabelStyle}>最少收費金額</InputLabel>
                            <TextField
                                value={formData.support_plan_min_charge}
                                onChange={setFormField("support_plan_min_charge")}
                                size="small"
                                fullWidth
                                inputProps={inputPropsStyle}
                                sx={inputSX}
                                disabled={!isEdit || formData.support_plan == "無"}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <InputLabel shrink sx={inputLabelStyle}>支援方案幣別</InputLabel>
                            <Select
                                value={formData.support_plan_currency}
                                onChange={setFormField("support_plan_currency")}
                                size="small"
                                fullWidth
                                SelectDisplayProps={SelectDisplayProps}
                                sx={inputSX}
                                disabled={!isEdit || formData.support_plan == "無"}
                            >
                                {currencies.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>


                        <Grid item xs={12}>
                            <Typography
                                sx={{ flex: '1 1 auto', textAlign: "left", fontWeight: "bold", padding: "20px 0px 0px" }}
                                id="tableTitle"
                                component="div"
                            >
                                稅務設定
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel shrink sx={inputLabelStyle}>稅別</InputLabel>
                            <Select
                                value={formData.tax_flag}
                                onChange={setFormField("tax_flag")}
                                size="small"
                                fullWidth
                                SelectDisplayProps={SelectDisplayProps}
                                sx={inputSX}
                                disabled={!isEdit}
                            >
                                {taxFlag.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>


                        <Grid item xs={12}>
                            <InputLabel shrink sx={inputLabelStyle}>合約備註</InputLabel>
                            <TextField
                                value={formData.note}
                                onChange={setFormField("note")}
                                size="small"
                                fullWidth
                                inputProps={inputPropsStyle}
                                sx={inputSX}
                                disabled={!isEdit}
                            />
                        </Grid>
                    </Grid>
                </form>

            </Grid>
        </LocalizationProvider>
    );
}


export default ContractInfo;