import React from "react";
import { Navigate, RouteObject } from "react-router-dom";
import App from "../App";
import Home from '../MainPage/Home';
import Stats from '../MainPage/Stats';
import IndividualCompanyLayout from "../MainPage/Company/IndividualCompanyLayout";
import CompanyInfo from "../MainPage/Company/CompanyInfo/CompanyInfo";
import Contract from "../MainPage/Contract/Contract";
import ContractInfo from "../MainPage/Contract/ContractInfo/ContractInfo";
import BillingAccountTable from "../MainPage/BillingAccount/BillingAccountTable";
import BillingAccountInfo from "../MainPage/BillingAccount/BillingAccountInfo/BillingAccountInfo";
import InvoiceUpload from "../MainPage/Invoice/InvoiceUpload/InvoiceUpload";
import CusInvoiceTable from "../MainPage/Invoice/CusInvoiceTable";
import RoleLayout from "../MainPage/Settings/Role/RoleLayout";
import RoleTable from "../MainPage/Settings/Role/RoleTable";
import CreateRoleForm from "../MainPage/Settings/Role/CreateRoleForm/CreateRoleForm";
import RoleForm from "../MainPage/Settings/Role/RoleForm/RoleForm";
import CusCreateAccountForm from "../MainPage/Account/CreateAccountForm";
import CusAccountForm from "../MainPage/Account/AccountForm";
import CusAccountTable from "../MainPage/Account/AccountTable";
import Login from "../MainPage/Login/Login";
import { permissions } from "../localStorage/permission";


// router management
// ref: https://github.com/wgm7512/react-router-dom-v6-example/blob/master/src/router/routes.tsx


const routes: RouteObject[] = [
    {
        path: "login",
        element: <Login />,
    },
    {
        path: "/",
        element: <App />,
        children: [
            {
                index: true,
                element: <Navigate to="/company/co" replace={true} />
            },
            {
                path: "stats",
                element: permissions.includes("TBD??") ? <Stats /> : <></>,
            },
            {
                path: "company",
                element: <Navigate to="/company/co" replace={true} />,
            },
            {
                path: "company/co",
                element: permissions.includes("CUS001") ? <IndividualCompanyLayout /> : <></>,
                children: [
                    {
                        path: "",
                        element: <Navigate to="info" replace={true} />
                    },
                    { path: "info", element: <CompanyInfo /> },
                    {
                        path: "billing_account",
                        children: [
                            {
                                index: true,
                                element: <BillingAccountTable />,
                            },
                            {
                                path: ":b_id",
                                element: <BillingAccountInfo />
                            },
                        ],
                    },
                    {
                        path: "contract",
                        children: [
                            {
                                index: true,
                                element: <Contract />,
                            },
                            {
                                path: ":contractId",
                                element: <ContractInfo />
                            },
                        ],
                    },
                    {
                        path: "invoice",
                        children: [
                            {
                                index: true,
                                element: <CusInvoiceTable />
                            },
                            {
                                path: ":invoiceId",
                                element: <InvoiceUpload />
                            },
                        ]
                    },
                    {
                        path: "account",
                        children: [
                            {
                                index: true,
                                element: permissions.includes("CUU001") ? <CusAccountTable /> : <></>
                            },
                            {
                                path: "newform",
                                element: permissions.includes("CUU002") ? <CusCreateAccountForm /> : <></>
                            },
                            {
                                path: ":accountId",
                                element: permissions.includes("CUU001") ? <CusAccountForm /> : <></>
                            },
                        ]
                    },
                ]
            },
            {
                path: "settings-role",
                element: permissions.includes("CUR001") ? <RoleLayout /> : <></>,
                children: [
                    {
                        index: true,
                        element: <RoleTable />,
                    },
                    {
                        path: "edit",
                        element: <></>,
                    },
                    {
                        path: "customer/newform",
                        element: permissions.includes("CUR002") ? <CreateRoleForm /> : <></>
                    },
                    {
                        path: "customer/:roleId",
                        element: <RoleForm />,
                    },
                ]
            },
        ]
    },

];
export default routes;
