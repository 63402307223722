import { useEffect, useState } from 'react';
import './App.css';

import { Navigate, Outlet } from "react-router-dom";

import { Box } from '@mui/material';

import SideBar from './SideBar/SideBar';
import { customGET } from './utils/customFetch';


const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

type Loggedin = "init" | "login" | "logout";


function App() {
    const [loggedin, setLoggedin] = useState<Loggedin>("init");
    const [userData, setUserData] = useState({});

    /**
     * check JWT to determine login or logout state
     */
    const auth = async () => {
        // initial state
        // valid -> login
        // expire or not exist -> logout
        const res = await fetch(`${BACKEND_URL}/api/v1/auth/refreshtoken`, {
            credentials: "include",
        });

        if (res.status == 200) {
            setLoggedin("login");


            // fetch permission list, store in localStorage
            let res = await customGET(`${BACKEND_URL}/api/v1/auth/permission`);
            let resJson = await res.json();
            const permissions = JSON.stringify(resJson.permissions);

            if (localStorage.getItem("permissions") != permissions) {
                localStorage.clear();
                localStorage.setItem("permissions", permissions);

                // reload to read the newest value from localStorage
                window.location.reload();
                return;
            }

            // get username, email, role
            res = await customGET(`${BACKEND_URL}/api/v1/auth/accountinfo`);

            setUserData(await res.json());

            return;
        } else {
            // refresh token expired or other error, require login
            setLoggedin("logout");
            return;
        }

        // var res = await fetch(`${BACKEND_URL}/api/v1/auth/setjwt`, {
        //     credentials: "include",
        // });
        // var res = await fetch(`${BACKEND_URL}/api/v1/auth/refreshtoken`, {
        //     credentials: "include",
        // });

    }

    useEffect(() => {
        auth();
    }, []);


    if (loggedin == "init")
        return (<></>);     // blank page

    else if (loggedin == "logout")
        return (<Navigate to="/login" replace={true} />);   // home page

    else
        return (
            <div className="App" style={{ background: "rgba(65,105,225,0.1)", overflow: "auto" }}>
                {/* <Stack direction="row"> */}
                <Box sx={{ display: 'flex' }}>
                    <SideBar userData={userData} />
                    <Outlet />
                </Box>
                {/* </Stack> */}
            </div>
        );
}

export default App;
