/**
 * adapted from Companyinfo.tsx
 */
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

// mui
import { Button, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';

// types
import { Account } from '../../types/Account.types';
import { Role } from '../../types/Role.types';
import { BillingAccount } from '../../types/Contract.types';

// css
import { SelectDisplayProps, inputLabelStyle, inputPropsStyle, inputSX } from '../../css/formStyle';

// components
import BindBillingAccountTable from '../BillingAccount/BindBillingAccount/BindBillingAccountTable';

// utils
import { customGET, customPOST } from '../../utils/customFetch';

// misc
import { enabled } from '../../shared/formSelectOptions';
import { SERVER_ERROR } from '../../shared/errorMessage';


const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;


// helper text
const RequiredLabel = () => (<span><span style={{ color: "red" }}>*</span> <span style={{ color: "grey" }}>(必填)</span></span>);


const initialFormData: Account = {
    id: "",
    role_id: "",
    role_name: "",
    customer_id: "",
    name: "",
    google_login_mail: "",
    status: "啟用",
    is_default: false,
}

// columns that require validation
const initErrColumn = {
    role_name: false,
    name: false,
    google_login_mail: false,
}

/**
 * The default post-action navigation will be overridden by closeCurrentPopup if closeCurrentPopup is not null
 */
function CreateAccountForm() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState<Account>(initialFormData); // render current form state
    const [errColumn, setErrColumn] = useState(initErrColumn);
    const [roles, setRoles] = useState<Role[]>([]);


    // billing account
    const [billingAccountList, setBillingAccountList] = useState<BillingAccount[]>([]); // list of selected billing account

    const id = "";


    const cancelAction = () => {
        navigate("../.");
    }

    const saveAction = async () => {
        // DEBUG
        // console.log(formData);
        // return;

        let isError = false;
        let newErrColumn = { ...errColumn };

        // validate form, mark incorrect column
        for (const key in errColumn) {
            newErrColumn[key] = formData[key].length == 0; // not empty

            isError = isError || newErrColumn[key];
        }

        setErrColumn(newErrColumn);

        if (isError) {
            alert("🔴 輸入格式錯誤");
            return;
        }


        const requestBody = {
            customer_account: {
                ...formData,
                customer_id: id,
                role_id: roles.filter(item => item.name == formData.role_name)[0].id, // name => id
                status: formData.status == "啟用",  // convert back to boolean
            },
            billing_account_list: billingAccountList
        };

        try {
            const res = await customPOST(`${BACKEND_URL}/api/v1/account/customer`, requestBody);
            const resJson = await res.json();

            if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }

            alert("🟢 Changes saved!");
            navigate("../.");

        } catch (error) {
            console.log(error);
            return;
        }
    }

    /**
    * set the value of a single field
    */
    const setFormField = <Key extends keyof Account>(field: Key) => (event: any) => {
        console.log("🖨️ ", event.target.value);
        const value = event.target.value;

        const newFormData = { ...formData };
        newFormData[field] = value;

        setFormData(newFormData);
    }


    const init_roles = async () => {
        // fetch all roles
        try {
            const param = new URLSearchParams({
                customer_id: id
            });

            const res = await customGET(`${BACKEND_URL}/api/v1/role/customer?${param}`);
            const resJson = await res.json();

            if (res.status != 200) {
                alert(SERVER_ERROR);
                throw new Error;
            }

            setRoles(resJson);

        } catch (error) {
            console.log(error);
            return;
        }
    }

    useEffect(() => {
        init_roles();
    }, []);

    return (
        // Container (base): wrap Page Title, Form
        <Grid sx={{ width: '100%', height: "70%", padding: "0px" }}>

            {/* white container */}
            {/* (adapted from Companyinfo.tsx) */}
            <Grid xs={12} sx={{ padding: "20px 20px", background: "white", marginTop: "10px", marginBottom: "10px", borderRadius: "20px" }}>

                <form >
                    <Grid container columnSpacing={5} rowSpacing={1} sx={{ height: "100%", textAlign: "left" }}>
                        <Grid container item xs={12}>
                            <Grid item>
                                <Typography
                                    sx={{ flex: '1 1 auto', textAlign: "left", fontWeight: "bold" }}
                                    id="tableTitle"
                                    component="div"
                                >
                                    新增使用者
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel shrink sx={inputLabelStyle} >使用者姓名 {<RequiredLabel />}</InputLabel>
                            <TextField
                                value={formData.name}
                                onChange={setFormField("name")}
                                size="small"
                                fullWidth
                                inputProps={inputPropsStyle}
                                sx={inputSX}
                                error={errColumn.name}
                            />

                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel shrink sx={inputLabelStyle} >Google 登入郵件地址 {<RequiredLabel />}</InputLabel>
                            <TextField
                                value={formData.google_login_mail}
                                onChange={setFormField("google_login_mail")}
                                size="small"
                                fullWidth
                                inputProps={inputPropsStyle}
                                sx={inputSX}
                                error={errColumn.google_login_mail}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel shrink sx={inputLabelStyle} >角色 {<RequiredLabel />}</InputLabel>
                            <Select
                                value={formData.role_name}
                                onChange={setFormField("role_name")}
                                size="small"
                                fullWidth
                                SelectDisplayProps={SelectDisplayProps}
                                sx={inputSX}
                                error={errColumn.role_name}
                            >

                                {roles.map((option) => (
                                    <MenuItem key={option.id} value={option.name}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel shrink sx={inputLabelStyle}>狀態</InputLabel>
                            <Select
                                value={formData.status}
                                onChange={setFormField("status")}
                                size="small"
                                fullWidth
                                SelectDisplayProps={SelectDisplayProps}
                                sx={inputSX}
                            >

                                {enabled.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>

                        {/* bind billing account */}
                        <Grid item xs={12}>
                            <BindBillingAccountTable billingAccountList={billingAccountList} setBillingAccountList={setBillingAccountList} disabled={false} />
                        </Grid>

                    </Grid>
                </form>

                <Grid xs={12} sx={{ width: '100%', display: "flex", justifyContent: "flex-end", padding: "30px 0px 0px" }}>
                    <Button variant="contained" color="inherit" size="small" sx={{ margin: "auto 10px" }} onClick={cancelAction}> 取消 </Button>
                    <Button variant="contained" color="primary" size="small" sx={{ margin: "auto 10px" }} onClick={saveAction}> 儲存 </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}


export default CreateAccountForm;